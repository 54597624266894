import React, { useEffect, useState } from "react";
import { Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DstButton from "../../../../components/DstButton/DstButton";
import DstConfirm from "../../../../components/DstConfirm/DstConfirm";
import DstTable from "../../../../components/DstTable/DstTable";
import DstTableSearch from "../../../../components/DstTable/DstTableSearch/DstTableSearch";
import DstTabs from "../../../../components/DstTabs/DstTabs";
import MultiSelectDropdown from "../../../../components/MultiSelectDropdown/MultiSelectDropdown";
import Popup from "../../../../components/Popup/Popup";
import { useSession } from "../../../../contexts/SessionContext";
import { formatToISODateTime, getAgeHumanly } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { completeNavigate } from "../../../../services/UtilityService";
import { Animator, Organizer } from "../../Meeting.d";
import {
  addNoteToMeeting,
  deleteMeeting,
  fetchMeetingNotes,
  getRessources,
  updateAnimators,
  updateMeeting,
} from "../../Meeting.function";
import "./ManageMeeting.scss";

const ManageMeeting = ({ meeting }: { meeting: any }) => {
  const { language } = useSession();
  const navigate = useNavigate();

  const [note, setNote] = useState<string>("");
  const [showDescription, setShowDescription] = useState(true);
  // const [showOverview, setshowOverview] = useState(true);
  const [showNotes, setShowNotes] = useState(true);
  const [activeTab, setActiveTab] = useState<string>("cohorts");
  const [showAllCohorts, setShowAllCohorts] = useState(false);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [filteredCohorts, setFilteredCohorts] = useState(meeting.cohorts);
  const [filteredUsers, setFilteredUsers] = useState(meeting.attendees);
  const [showEditModal, setShowEditModal] = useState(false);
  const [animators, setAnimators] = useState([]);
  const [showAnimatorModal, setShowAnimatorModal] = useState(false);
  const [generateZoomLink, setGenerateZoomLink] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isUpdatingAnimators, setIsUpdatingAnimators] = useState(false);
  const [organizers, setOrganizers] = useState<Organizer[]>([]);
  const [notes, setNotes] = useState<any[]>([]);
  const [loadingNotes, setLoadingNotes] = useState<boolean>(false);

  const [selectedAnimators, setSelectedAnimators] = useState(
    meeting.animators.map((animator: { username: string; first_name: string; last_name: string }) => ({
      username: animator.username,
      display: `${animator.first_name} ${animator.last_name}`,
    }))
  );

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    start: formatToISODateTime(meeting.start, true) || "",
    end: formatToISODateTime(meeting.end, true) || "",
    meeting_name: meeting.meeting_name || "",
    visibility: meeting.visibility || "",
    organizer_id: meeting.organizer_id || "",
    meeting_type_id: meeting.meeting_type_id || "",
    meeting_description: meeting.meeting_description || "",
    meeting_link: meeting.meeting_link || "",
  });

  const MAX_VISIBLE_ROWS = 3;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setLoading(true);
    getRessources(setOrganizers, setAnimators, language, () => {
      setLoading(false);
    });
  }, [language]);

  const cohortColumns = [
    {
      key: "name",
      name: translate(language, "PAGES.MEETING.MANAGE_MEETING.COHORT_COLUMN.NAME"),
      render: (row: any) => (
        <span className="clickable" onClick={(event) => completeNavigate(event, navigate, `/cohort/${row.id}`)}>
          {row.name}
        </span>
      ),
    },
    {
      key: "cohort_leader",
      name: translate(language, "PAGES.MEETING.MANAGE_MEETING.COHORT_COLUMN.COHORT_LEADER"),
      render: (row: any) =>
        row.cohort_leader ? (
          <span
            className="clickable"
            onClick={(event) => completeNavigate(event, navigate, `/user/${row.cohort_leader.id}`)}
          >
            {row.cohort_leader.full_name}
          </span>
        ) : (
          "N/A"
        ),
    },
    {
      key: "program_manager",
      name: translate(language, "PAGES.MEETING.MANAGE_MEETING.COHORT_COLUMN.PROGRAM_MANAGER"),
      render: (row: any) =>
        row.program_manager ? (
          <span
            className="clickable"
            onClick={(event) => completeNavigate(event, navigate, `/user/${row.program_manager.id}`)}
          >
            {row.program_manager.full_name}
          </span>
        ) : (
          "N/A"
        ),
    },
  ];

  const userColumns = [
    {
      key: "name",
      name: translate(language, "PAGES.MEETING.MANAGE_MEETING.USER_COLUMN.NAME"),
      render: (row: any) => (row.first_name && row.last_name ? `${row.first_name} ${row.last_name}` : "N/A"),
    },
    {
      key: "username",
      name: translate(language, "PAGES.MEETING.MANAGE_MEETING.USER_COLUMN.EMAIL"),
      render: (row: any) => row.username ?? "N/A",
    },
    {
      key: "badges",
      name: translate(language, "PAGES.MEETING.MANAGE_MEETING.USER_COLUMN.FLAGS.TITLE"),
      render: (row: any) =>
        row.flags.length
          ? row.flags.map((flag: any) => (
              <span title={flag.name} className={`blue-font dst-icon-${flag.icon_name}`}></span>
            ))
          : translate(language, "PAGES.MEETING.MANAGE_MEETING.USER_COLUMN.FLAGS.NONE"),
      sortCompute: (row: any) => row.flags.length,
    },
  ];

  const tabsData = [
    {
      id: "cohorts",
      title: translate(language, "PAGES.MEETING.MANAGE_MEETING.TABS.COHORTS.TITLE"),
      content: (
        <React.Fragment>
          <DstTable
            columns={cohortColumns}
            data={showAllCohorts ? filteredCohorts : filteredCohorts.slice(0, MAX_VISIBLE_ROWS)}
            striped
          />
          {filteredCohorts.length > MAX_VISIBLE_ROWS && (
            <DstButton
              value={
                showAllCohorts
                  ? translate(language, "PAGES.MEETING.MANAGE_MEETING.TABS.COHORTS.BUTTON.SEE_LESS")
                  : translate(language, "PAGES.MEETING.MANAGE_MEETING.TABS.COHORTS.BUTTON.VIEW_ALL_COHORTS").replace(
                      "{{REMAINING_COHORTS_COUNT}}",
                      (filteredCohorts.length - MAX_VISIBLE_ROWS).toString()
                    )
              }
              variant="light"
              btnClass="mt-2 w-100"
              clickFunction={() => setShowAllCohorts(!showAllCohorts)}
            />
          )}
        </React.Fragment>
      ),
    },
    {
      id: "users",
      title: "Users",
      content: (
        <React.Fragment>
          <DstTable
            columns={userColumns}
            data={showAllUsers ? filteredUsers : filteredUsers.slice(0, MAX_VISIBLE_ROWS)}
            striped
            onRowClick={(event, row) => completeNavigate(event, navigate, `/user/${row.user_id}`)}
          />
          {filteredUsers.length > MAX_VISIBLE_ROWS && (
            <DstButton
              value={
                showAllUsers
                  ? translate(language, "PAGES.MEETING.MANAGE_MEETING.TABS.USERS.BUTTON.SEE_LESS")
                  : translate(language, "PAGES.MEETING.MANAGE_MEETING.TABS.USERS.BUTTON.VIEW_ALL_COHORTS").replace(
                      "{{TOTAL_USERS_COUNT}}",
                      filteredUsers.length.toString()
                    )
              }
              variant="light"
              btnClass="mt-2 w-100"
              clickFunction={() => setShowAllUsers(!showAllUsers)}
            />
          )}
        </React.Fragment>
      ),
    },
  ];

  const prepareSearchableCohorts = (data: any[]) => {
    return data.map((item) => ({
      ...item,
      cohort_leader_name: item.cohort_leader
        ? `${item.cohort_leader.first_name} ${item.cohort_leader.last_name}`.toLowerCase()
        : "n/a",
      program_manager_name: item.program_manager
        ? `${item.program_manager.first_name} ${item.program_manager.last_name}`.toLowerCase()
        : "n/a",
    }));
  };

  const prepareSearchableUsers = (data: any[]) => {
    return data.map((item) => ({
      ...item,
      user_full_name: item.first_name && item.last_name ? `${item.first_name} ${item.last_name}`.toLowerCase() : "n/a",
      user_email: item.email ? item.email.toLowerCase() : "n/a",
    }));
  };

  useEffect(() => {
    fetchMeetingNotes(meeting.id, language, setNotes, setLoadingNotes);
  }, [meeting.id, language]);

  return (
    <div className="s-regular">
      <Row>
        <Col lg>
          {/* <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="m-bold mb-2">
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.MEETING_STATUS_OVERVIEW.TITLE")}
            </div>
            <i
              className={`clickable dst-icon-chevron-${showOverview ? "up" : "down"}`}
              onClick={() => setshowOverview(!showOverview)}
            />
          </div>
          {showOverview && (
            <Row className="d-flex justify-content-between mx-0">
              <Col sm className="mb-2 mb-sm-0 status-card active p-3 me-3 rounded border">
                <div className="circle-outline mb-2">
                  <div className="circle-filled"></div>
                </div>
                <p className="mb-2">
                  {translate(language, "PAGES.MEETING.MANAGE_MEETING.MEETING_STATUS_OVERVIEW.MEETING_PLANNED")}
                </p>
                <DstButton
                  btnImageBefore={<i className="dst-icon-video-recorder me-2" />}
                  value={translate(language, "PAGES.MEETING.MANAGE_MEETING.MEETING_STATUS_OVERVIEW.JOIN_MEETING")}
                  btnClass="btn btn-primary"
                  link={meeting.meeting_link}
                />
              </Col>
              <Col sm className="mb-2 mb-sm-0 status-card p-3 me-3 rounded border">
                <div className="circle-outline mb-2">
                  <div className="circle-empty"></div>
                </div>
                <p className="mb-0">
                  {translate(language, "PAGES.MEETING.MANAGE_MEETING.MEETING_STATUS_OVERVIEW.ATTENDANCE_LOCKED")}
                </p>
              </Col>
              <Col sm className="mb-2 mb-sm-0 status-card p-3 rounded border">
                <div className="circle-outline mb-2">
                  <div className="circle-empty"></div>
                </div>
                <p className="mb-0">
                  {translate(language, "PAGES.MEETING.MANAGE_MEETING.MEETING_STATUS_OVERVIEW.MESSAGE_SENT")}
                </p>
              </Col>
            </Row>
          )} */}
          <div className="d-flex align-items-center justify-content-between mb-3">
            <h3 className="mb-0 m-bold">{translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.TITLE")}</h3>
            <div className="d-flex">
              <DstButton
                btnClass="me-2"
                btnImageBefore={<i className="dst-icon-trash-01 me-2" />}
                value={translate(language, "PAGES.MEETING.MANAGE_MEETING.BUTTON.DELETE")}
                variant="danger"
                clickFunction={() => setShowConfirm(true)}
              />
              <DstConfirm
                show={showConfirm}
                onHide={() => {
                  setShowConfirm(false);
                }}
                onConfirm={() => {
                  deleteMeeting(meeting.id, language, setLoading, () => navigate("/meetings"));
                  setShowConfirm(false);
                }}
                title={translate(language, "PAGES.MEETING.MANAGE_MEETING.POPUP_DELETION.TITLE")}
                messageExplain={translate(language, "PAGES.MEETING.MANAGE_MEETING.POPUP_DELETION.EXPLAIN")}
                messageConfirm={translate(language, "PAGES.MEETING.MANAGE_MEETING.POPUP_DELETION.CONFIRM")}
                confirmTextButton={translate(language, "PAGES.MEETING.MANAGE_MEETING.POPUP_DELETION.BUTTON")}
              />
              <DstButton
                btnImageBefore={<i className="dst-icon-edit-02 me-2" />}
                value={translate(language, "PAGES.MEETING.MANAGE_MEETING.BUTTON.EDIT")}
                variant="secondary"
                clickFunction={() => setShowEditModal(true)}
              />
            </div>
          </div>
          {/* <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
            <div>
              <i className="dst-icon-search-md blue-font me-2" />
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.POLE")}
            </div>
            <div>{meeting.pole}</div>
          </div> */}
          <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
            <div>
              <i className="dst-icon-clipboard blue-font me-2" />
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.MEETING_TYPE")}
            </div>
            <div>{meeting.meeting_type.meeting_meta_type}</div>
          </div>
          <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
            <div>
              <i className="dst-icon-pencil-line blue-font me-2" />
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.MANDATORY_ATTENDANCE.TITLE")}
            </div>
            <div>
              {meeting.edusign_locked
                ? translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.MANDATORY_ATTENDANCE.YES")
                : translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.MANDATORY_ATTENDANCE.NO")}
            </div>
          </div>
          <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
            <div>
              <i className="dst-icon-grid-03 blue-font me-2" />
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.MEETING_ID")}
            </div>
            <div>{meeting.id}</div>
          </div>
          <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
            <div>
              <i className="dst-icon-globe-05 blue-font me-2" />
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.LANGUAGE_SPOKEN")}
            </div>
            <div>
              {meeting?.meeting_type.meeting_type_extra_data?.language
                ? meeting.meeting_type.meeting_type_extra_data.language
                : "N/A"}
            </div>
          </div>
          <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
            <div>
              <i className="dst-icon-user-circle blue-font me-2" />
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.ORGANIZER")}
            </div>
            <div>{meeting.organizer_id}</div>
          </div>
          <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
            <div className="text-nowrap align-content-center">
              <i className="dst-icon-link-01 blue-font me-2" />
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.MEETING_LINK.TITLE")}
            </div>
            {meeting.zoom_meeting_info?.join_url ? (
              <div className="text-end">
                <a href={meeting.zoom_meeting_info.join_url} target="_blank" rel="noopener noreferrer">
                  {meeting.zoom_meeting_info.join_url}
                </a>
              </div>
            ) : meeting.meeting_link ? (
              <div className="d-flex align-items-center">
                {meeting.meeting_link}
                <i
                  className="dst-icon-copy-03 ms-2 clickable"
                  onClick={() => {
                    navigator.clipboard.writeText(meeting.meeting_link).then(
                      () =>
                        alert(translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.MEETING_LINK.COPY_LINK")),
                      (err) =>
                        console.error(
                          translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.MEETING_LINK.COPY_ERROR"),
                          err
                        )
                    );
                  }}
                />
              </div>
            ) : (
              <span>N/A</span>
            )}
          </div>
          <div className="d-flex justify-content-between border-bottom mb-2 pb-2">
            <div>
              <i className="dst-icon-eye-off blue-font me-2" />
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.VISIBILITY")}
            </div>
            <div>{meeting.visibility}</div>
          </div>
          <div className="d-flex align-items-center justify-content-between my-3">
            <h3 className="mb-0 m-bold">
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.DESCRIPTION.TITLE")}
            </h3>
            <i
              className={`clickable dst-icon-chevron-${showDescription ? "up" : "down"}`}
              onClick={() => setShowDescription(!showDescription)}
            />
          </div>
          {showDescription && (
            <p className="mb-0 megium-gray-background p-3 rounded w-100 s-regular">{meeting.meeting_description}</p>
          )}
          <div className="d-flex align-items-center justify-content-between my-3">
            <h3 className="mb-0 m-bold">
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.NOTE.TITLE")}
            </h3>
            <i
              className={`clickable dst-icon-chevron-${showNotes ? "up" : "down"}`}
              onClick={() => setShowNotes(!showNotes)}
            />
          </div>
          {showNotes && (
            <div>
              <div className="d-flex">
                <Form.Control
                  as="textarea"
                  rows={1}
                  placeholder={translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.NOTE.PLACEHOLDER")}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  className="megium-gray-background s-regular"
                  style={{ resize: "none" }}
                />
                <DstButton
                  value={translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.NOTE.PUBLISH")}
                  btnClass="d-flex align-items-center ms-2"
                  clickFunction={() => addNoteToMeeting(meeting.id, note, language, setNote, setNotes, setLoadingNotes)}
                  variant="secondary"
                  btnImageAfter={<i className="dst-icon-send-03 ms-2" />}
                />
              </div>
              {loadingNotes ? (
                <div className="mt-3 text-center">
                  <Spinner animation="border" size="sm" role="status" />
                </div>
              ) : (
                <div className="mt-3 scrollable" style={{ maxHeight: 300 }}>
                  {notes.length > 0 &&
                    notes
                      .sort((a, b) => new Date(b.dt_created).getTime() - new Date(a.dt_created).getTime())
                      .map((note) => (
                        <div key={note.id} className="note-item border rounded p-2 mb-2 s-regular">
                          <p className="my-1">{note.content}</p>
                          <div className="d-flex justify-content-between dark-gray-font">
                            <span>{note.author}</span>
                            <span>
                              {getAgeHumanly(language, new Date(note.dt_created).getTime())}{" "}
                              {note.dt_modified && (
                                <OverlayTrigger
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-note-${note.id}-edit-time`}>
                                      {getAgeHumanly(language, new Date(note.dt_modified).getTime())}
                                    </Tooltip>
                                  }
                                >
                                  <span className="clickable">
                                    {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.NOTE.EDITED")}
                                  </span>
                                </OverlayTrigger>
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                </div>
              )}
            </div>
          )}
        </Col>
        <Col className="mt-3 mt-lg-0">
          <div className="m-regular d-flex justify-content-between align-items-center mb-2">
            <div className="me-3">{translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.ANIMATORS")}</div>

            <DstButton
              value={translate(language, "PAGES.MEETING.MANAGE_MEETING.BUTTON.ADD_ANIMATOR")}
              btnImageBefore={
                isUpdatingAnimators ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                ) : (
                  <i className="dst-icon-plus me-2" />
                )
              }
              clickFunction={() => setShowAnimatorModal(true)}
              disabled={isUpdatingAnimators}
            />
          </div>
          <div className="mt-3 mt-lg-0">
            {selectedAnimators && selectedAnimators.length > 0 ? (
              <div className="d-inline-flex flex-wrap">
                {selectedAnimators.map((animator: any, index: any) => (
                  <div
                    key={index}
                    className="animator-badge border border-primary blue-vibrant-font rounded p-2 me-2 mb-2 d-flex align-items-center"
                  >
                    {animator.display}
                    {isUpdatingAnimators ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="ms-3"
                      />
                    ) : (
                      <i
                        className="dst-icon-x m-regular ms-2 clickable"
                        onClick={() => {
                          updateAnimators(
                            meeting.id,
                            selectedAnimators.filter(
                              (selectedAnimator: any) => selectedAnimator.username !== animator.username
                            ),
                            setSelectedAnimators,
                            generateZoomLink,
                            language,
                            setIsUpdatingAnimators
                          );
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <span className="text-danger">
                {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.NO_ANIMATORS")}
              </span>
            )}
          </div>
          <Row className="d-flex mt-3 align-items-center">
            <Col className="m-regular">
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.ATTENDEES")} ({meeting.attendees.length})
            </Col>
            <Col>
              <DstTableSearch
                data={
                  activeTab === "cohorts"
                    ? prepareSearchableCohorts(meeting.cohorts)
                    : prepareSearchableUsers(meeting.attendees)
                }
                searchableColumns={
                  activeTab === "cohorts"
                    ? ["name", "cohort_leader_name", "program_manager_name"]
                    : ["user_full_name", "user_email"]
                }
                onSearch={(results) => {
                  if (activeTab === "cohorts") {
                    setFilteredCohorts(results);
                  } else if (activeTab === "users") {
                    setFilteredUsers(results);
                  }
                }}
              />
            </Col>
          </Row>
          <DstTabs
            tabsData={tabsData}
            variant="primary"
            classes="mt-3"
            activeKey={activeTab}
            onSelect={(key) => setActiveTab(key || "cohorts")}
          />
          {/* <h3 className="m-bold my-3">
            {translate(language, "PAGES.MEETING.MANAGE_MEETING.INFORMATION.MEETING_HISTORY")}
          </h3>
          {meeting.meeting_history.map((historyItem: any, index: number) => (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center border-bottom mb-3 pb-2 s-regular"
            >
              <div className="d-flex align-items-center">
                <i
                  className={`me-3 ${
                    historyItem.action === "removed"
                      ? "dst-icon-user-x-01 red-bright-font"
                      : "dst-icon-user-check-01 green-font"
                  }`}
                />
                <span>
                  <strong>{historyItem.actor}</strong>{" "}
                  <span className="text-muted">
                    {historyItem.action === "set" ? "set" : "removed"} <strong>{historyItem.affected_person}</strong> as{" "}
                    <strong>{historyItem.role}</strong>
                  </span>
                </span>
              </div>
              <div className="text-muted">{formatDateHumanly(language, historyItem.timestamp, true)}</div>
            </div>
          ))} */}
        </Col>
      </Row>
      <Popup
        show={showAnimatorModal}
        onClose={() => setShowAnimatorModal(false)}
        modalTitle={translate(language, "PAGES.MEETING.MANAGE_MEETING.BUTTON.ADD_ANIMATOR")}
      >
        <div className="d-flex flex-column">
          <MultiSelectDropdown
            showAlwaysConfirm
            mock={loading}
            items={animators.filter(
              (user: Animator) =>
                !selectedAnimators.map((animator: Animator) => animator.username).includes(user.username)
            )}
            displayItem={(item) => item.full_name}
            badgeItemProperty="full_name"
            onConfirm={(selectedItems) => {
              setSelectedItems(
                selectedItems.map((item) => ({
                  username: item.username,
                  first_name: item.first_name,
                  last_name: item.last_name,
                }))
              );
            }}
            label={
              selectedItems.length > 0
                ? `${selectedItems[0].first_name} ${selectedItems[0].last_name}`
                : translate(language, "PAGES.MEETING.MANAGE_MEETING.BUTTON.SELECT_ANIMATOR")
            }
            language={language}
            hideCaret
            borderToggle
            isSingleSelect
          />

          <div className="mt-3 d-flex align-items-center">
            <input
              type="checkbox"
              id="zoom-link-checkbox"
              checked={generateZoomLink}
              onChange={(e) => setGenerateZoomLink(e.target.checked)}
            />
            <label htmlFor="zoom-link-checkbox" className="ms-2">
              {translate(language, "PAGES.MEETING.MANAGE_MEETING.BUTTON.GENERATE_ZOOM_LINK")}
            </label>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <DstButton
              btnWidth="100px"
              variant="primary"
              value={translate(language, "PAGES.MEETING.MANAGE_MEETING.BUTTON.CONFIRM")}
              clickFunction={() => {
                updateAnimators(
                  meeting.id,
                  [...selectedAnimators, ...selectedItems],
                  setSelectedAnimators,
                  generateZoomLink,
                  language,
                  setIsUpdatingAnimators
                );
                setSelectedItems([]);
                setShowAnimatorModal(false);
              }}
              btnClass="me-2"
            />
          </div>
        </div>
      </Popup>
      <Popup
        show={showEditModal}
        onClose={() => setShowEditModal(false)}
        modalTitle={translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.TITLE")}
        size="lg"
      >
        <Form>
          <Form.Group controlId="formMeetingName">
            <Form.Label>{translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.NAME")}</Form.Label>
            <Form.Control type="text" name="meeting_name" value={formData.meeting_name} onChange={handleInputChange} />
          </Form.Group>

          <Form.Group controlId="formMeetingDescription" className="mt-3">
            <Form.Label>{translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.DESCRIPTION")}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="meeting_description"
              value={formData.meeting_description}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group controlId="formStart" className="mt-3">
            <Form.Label>{translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.START_DATE")}</Form.Label>
            <Form.Control type="datetime-local" name="start" value={formData.start} onChange={handleInputChange} />
          </Form.Group>

          <Form.Group controlId="formEnd" className="mt-3">
            <Form.Label>{translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.END_DATE")}</Form.Label>
            <Form.Control type="datetime-local" name="end" value={formData.end} onChange={handleInputChange} />
          </Form.Group>

          <Form.Group controlId="formVisibility" className="mt-3">
            <Form.Label>{translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.VISIBILITY.TITLE")}</Form.Label>
            <Form.Select name="visibility" value={formData.visibility} onChange={handleInputChange}>
              <option value="datascientest">
                {translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.VISIBILITY.DATASCIENTEST")}
              </option>
              <option value="private">
                {translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.VISIBILITY.PRIVATE")}
              </option>
            </Form.Select>
          </Form.Group>

          <Form.Group controlId="formOrganizerId" className="mt-3">
            <Form.Label>{translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.ORGANIZER_ID.TITLE")}</Form.Label>
            <MultiSelectDropdown
              items={organizers}
              displayItem={(item) => item.full_name}
              badgeItemProperty="full_name"
              isSingleSelect={true}
              language={language}
              onConfirm={(selectedItems) => {
                if (selectedItems.length > 0) {
                  const selectedOrganizer = selectedItems[0];
                  setFormData((prevData) => ({
                    ...prevData,
                    organizer_id: selectedOrganizer.username,
                  }));
                }
              }}
              label={
                formData.organizer_id
                  ? organizers.find((org) => org.username === formData.organizer_id)?.full_name ||
                    translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.ORGANIZER_ID.SELECT_ORGANIZER")
                  : translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.ORGANIZER_ID.SELECT_ORGANIZER")
              }
              toggleClass="w-100"
              borderToggle
            />
          </Form.Group>

          <Form.Group controlId="formMeetingLink" className="mt-3">
            <Form.Label>{translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.LINK")}</Form.Label>
            <Form.Control type="text" name="meeting_link" value={formData.meeting_link} onChange={handleInputChange} />
          </Form.Group>

          <DstButton
            value={translate(language, "PAGES.MEETING.MANAGE_MEETING.EDIT_POPUP.SAVE_CHANGES")}
            clickFunction={() => updateMeeting(meeting.id, { ...formData }, language, setShowEditModal, setLoading)}
            btnClass="mt-4"
          />
        </Form>
      </Popup>
    </div>
  );
};

export default ManageMeeting;
