import { autocomplete } from "@algolia/autocomplete-js";
import "@algolia/autocomplete-theme-classic";
import { meilisearchAutocompleteClient } from "@meilisearch/autocomplete-client";
import React, { useEffect, useRef } from "react";
import { Container, InputGroup, Nav, NavDropdown, Navbar, Placeholder } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { useSession } from "../../contexts/SessionContext";
import { PermissionComponent } from "../../services/PermissionGuard";
import ShortcutService from "../../services/ShortcutService";
import translate from "../../services/Translate";
import LanguageSelector from "../language-selector/LanguageSelector";
import { getMeilisearchSources, saveResultInHistory } from "./DstHeader.function";
import "./DstHeader.scss";

const DstHeader = ({ language }: { language: string }) => {
  const { first_name, handleLogout, loaded, user_id } = useSession();
  const container = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!container.current) {
      return;
    }
    const searchClient = meilisearchAutocompleteClient({
      url: process.env.REACT_APP_MEILISEARCH_URL!,
      apiKey: process.env.REACT_APP_MEILISEARCH_API_KEY,
    });

    const listen_destructor = ShortcutService.listen(
      "k",
      () => container.current?.getElementsByTagName("input")?.[0].focus(),
      true
    );

    const searchCore = autocomplete({
      container: container.current!,
      placeholder: translate(language, "COMPONENTS.HEADER.SEARCH_PLACEHOLDER").replace(
        "{{control}}",
        (navigator?.platform || (navigator as any)?.userAgentData?.platform).toUpperCase().includes("MAC")
          ? "⌘"
          : "Ctrl"
      ),
      defaultActiveItemId: 0,
      getSources({ query }: { query: string }) {
        return getMeilisearchSources(searchClient, query, !query);
      },
      openOnFocus: true,
      navigator: {
        navigate({ itemUrl, item }) {
          saveResultInHistory(itemUrl, item);
          window.location.assign(itemUrl);
        },
        navigateNewTab({ itemUrl, item }) {
          saveResultInHistory(itemUrl, item);
          const windowReference = window.open(itemUrl, "_blank", "noopener");

          if (windowReference) {
            windowReference.focus();
          }
        },
        navigateNewWindow({ itemUrl, item }) {
          saveResultInHistory(itemUrl, item);
          window.open(itemUrl, "_blank", "noopener");
        },
      },
    });

    return () => {
      listen_destructor();
      searchCore.destroy();
    };
  }, [language]);

  return (
    <Navbar expand="xl" id="navbar">
      <Container fluid>
        <Navbar.Brand>
          <Link to="/">
            <img src="/assets/images/nexus.svg" alt="DataScientest" height="30px" className="mb-1" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="justify-content-center justify-content-xl-between align-items-center xs-bold w-100">
            <PermissionComponent neededPermission={[{ methods: ["GET"], route: "/roles" }]}>
              <NavLink className="d-flex align-items-center" to="/permissions">
                <span className="dst-icon-shield-03 me-1" />
                {translate(language, "COMPONENTS.HEADER.ADMIN")}
              </NavLink>
            </PermissionComponent>
            <NavLink className="d-flex align-items-center" to="/users">
              <span className="dst-icon-folder-lock me-1" />
              {translate(language, "COMPONENTS.HEADER.TRAIN")}
            </NavLink>
            <PermissionComponent neededPermission={[{ methods: ["GET"], route: "/insights/users" }]}>
              <NavLink className="d-flex align-items-center" to="/insights">
                <span className="dst-icon-data me-1" /> {translate(language, "COMPONENTS.HEADER.INSIGHTS")}
              </NavLink>
            </PermissionComponent>
            <NavLink
              className="d-flex align-items-center"
              to="/evaluations/attempts?status=CORRECTION_PENDING,PRE_CORRECTED"
            >
              <span className="dst-icon-graduation-hat-02 me-1" />
              {translate(language, "COMPONENTS.HEADER.ATTEMPTS")}
            </NavLink>
            <NavLink className="d-flex align-items-center" to="/qcm">
              <span className="dst-icon-file-04 me-1" /> {translate(language, "COMPONENTS.HEADER.QCM")}
            </NavLink>
            <NavLink className="d-flex align-items-center" to="/invitations">
              <span className="dst-icon-image-user-plus me-1" />
              {translate(language, "COMPONENTS.HEADER.INVITATION")}
            </NavLink>
            <NavLink className="d-flex align-items-center" to="/api">
              <span className="dst-icon-settings-01 me-1" />
              {translate(language, "COMPONENTS.HEADER.API")}
            </NavLink>
            <NavLink className="d-flex align-items-center" to="/dev">
              <span className="dst-icon-monitor-01 me-1" />
              {translate(language, "COMPONENTS.HEADER.DEV")}
            </NavLink>
            <NavLink className="d-flex align-items-center" to="/meetings">
              <span className="dst-icon-clock me-1" />
              {translate(language, "COMPONENTS.HEADER.MEETING")}
            </NavLink>
            <NavLink className="d-flex align-items-center" to="/diplomas">
              <span className="dst-icon-paperclip me-1" />
              {translate(language, "COMPONENTS.HEADER.DIPLOMAS")}
            </NavLink>
            <NavLink className="d-flex align-items-center" to="/projects">
              <span className="dst-icon-globe-02 me-1" />
              {translate(language, "COMPONENTS.HEADER.PROJECT")}
            </NavLink>
            <PermissionComponent neededPermission={[{ methods: ["GET"], route: "/messages" }]}>
              <NavLink className="d-flex align-items-center" to="/messages">
                <span className="dst-icon-mail-02 me-1" />
                {translate(language, "COMPONENTS.HEADER.MESSAGES")}
              </NavLink>
            </PermissionComponent>
            <div className="d-flex align-items-center">
              <InputGroup>
                <div ref={container}></div>
              </InputGroup>
              <NavDropdown
                title={
                  loaded ? (
                    first_name
                  ) : (
                    <Placeholder animation="glow">
                      <Placeholder xs={12} />
                    </Placeholder>
                  )
                }
                id="basic-nav-dropdown"
                align="end"
                style={{ minWidth: 60 }}
              >
                <NavDropdown.Item className="xs-bold" onClick={() => loaded && navigate(`/user/${user_id}`)}>
                  {loaded ? (
                    <React.Fragment>
                      <span className="dst-icon-image-user me-1" />
                      {translate(language, "COMPONENTS.HEADER.MY_ACCOUNT")}
                    </React.Fragment>
                  ) : (
                    <Placeholder as="span" animation="glow">
                      <Placeholder xs={6} />
                    </Placeholder>
                  )}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.1" className="xs-bold">
                  <span className="dst-icon-settings-01 me-1" /> {translate(language, "COMPONENTS.HEADER.SETTINGS")}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <LanguageSelector className="ms-2" fontColor="black-font" />
                <NavDropdown.Divider />
                <NavDropdown.Item className="xs-bold" onClick={() => handleLogout()}>
                  <span className="dst-icon-log-out-01 me-1" /> {translate(language, "COMPONENTS.HEADER.LOGOUT")}
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default DstHeader;
