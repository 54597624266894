import React, { useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Placeholder, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DstButton from "../../../../components/DstButton/DstButton";
import DstTable from "../../../../components/DstTable/DstTable";
import DstTableSearch from "../../../../components/DstTable/DstTableSearch/DstTableSearch";
import DstTabs from "../../../../components/DstTabs/DstTabs";
import { isPast } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { completeNavigate } from "../../../../services/UtilityService";
import { sprintModules, sprintObject, sprintUser } from "./CohortSprint.d";
import { allMandatoryModulesValidated, fetchModules, fetchSprint, saveSprints } from "./CohortSprint.function";
import "./CohortSprint.scss";
import CohortSprintPopup from "./CohortSprintPopup/CohortSprintPopup";

const CohortSprint = ({ cohortId, language }: { cohortId: string; language: string }) => {
  const navigate = useNavigate();
  const [sprints, setSprints] = useState<sprintObject[]>([]);
  const [modules, setModules] = useState<any[]>([]);
  const [selectedSprint, setSelectedSprint] = useState<number | null>(null);
  const [loadingSprints, setLoadingSprints] = useState(true);
  const [loadingModules, setLoadingModules] = useState(true);
  const [filteredData, setFilteredData] = useState<sprintUser[]>([]);

  const loadSprintData = useCallback(() => {
    fetchSprint(
      cohortId,
      language,
      (sprints: sprintObject[]) => {
        setSprints(sprints);
        if (sprints.length > 0) {
          const currentSprint = sprints.find((data: sprintObject) => !!data.current_sprint);
          setSelectedSprint(currentSprint ? currentSprint.sprint.id : sprints[0].sprint.id);
        }
      },
      setLoadingSprints
    );
  }, [cohortId, language]);

  useEffect(() => {
    loadSprintData();
  }, [loadSprintData]);

  const columns = useCallback(
    (sprintId: number) => [
      { name: translate(language, "PAGES.COHORT.SPRINT.USER_ID"), key: "id" },
      { name: translate(language, "PAGES.COHORT.SPRINT.LAST_NAME"), key: "last_name" },
      { name: translate(language, "PAGES.COHORT.SPRINT.FIRST_NAME"), key: "first_name" },
      { name: translate(language, "PAGES.COHORT.SPRINT.EMAIL"), key: "email" },
      { name: translate(language, "PAGES.COHORT.SPRINT.MANDATORY_EXAMS"), key: "mandatory_exams" },
      ...sprints
        .find((sprint) => sprint.sprint.id === sprintId)!
        .modules.map(({ module, is_mandatory, name }: sprintModules) => ({
          name: (
            <React.Fragment>
              {name}
              {!is_mandatory && (
                <OverlayTrigger
                  key={`tooltip-${module}`}
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-${module}`}>{translate(language, "PAGES.COHORT.SPRINT.OPTIONAL")}</Tooltip>
                  }
                >
                  <i className="dst-icon-file-plus-02 ms-1 align-text-bottom" />
                </OverlayTrigger>
              )}
            </React.Fragment>
          ),
          key: `sprint_${sprintId}_module_${module}`,
          sortCompute: (line: sprintUser) => {
            const thisModule = line.modules_info.find((tmp_module) => tmp_module.module_id === module)!;
            if (!thisModule || !thisModule.total_exercises) return 0;
            return thisModule.completed_exercises / thisModule.total_exercises;
          },
          render: (line: sprintUser) => {
            const thisModule = line.modules_info.find((tmp_module) => tmp_module.module_id === module)!;
            if (!thisModule || !thisModule.total_exercises) return "N/A";
            return `${thisModule.completed_exercises}/${thisModule.total_exercises}`;
          },
          stylishCell: (line: sprintUser) => {
            const thisModule = line.modules_info.find((tmp_module) => tmp_module.module_id === module)!;
            return {
              backgroundColor:
                thisModule && thisModule.completed_exercises === thisModule.total_exercises
                  ? "var(--color-medium-green)"
                  : "var(--color-white)",
              color: "var(--color-black)",
            };
          },
          stylishTitle: () => (is_mandatory ? {} : { color: "dimgray" }),
        })),
    ],
    [language, sprints]
  );

  const [showPopup, setShowPopup] = useState(false);
  const [activeKey, setActiveKey] = useState("sprint-0");

  if (loadingSprints) {
    return (
      <Placeholder animation="glow">
        <div className="mx-3">
          <div className="d-flex justify-content-between mb-3">
            <div className="d-flex">
              <Placeholder className="me-3 align-content-center mb-0" as="h2" xs={12} md={6} />
              <Placeholder as="input" xs={12} md={4} />
            </div>
            <DstButton mock btnWidth="100px" />
          </div>
        </div>
      </Placeholder>
    );
  }

  return (
    <div className="mx-3">
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex">
          <h2 className="me-3 align-content-center mb-0">{translate(language, "PAGES.COHORT.SPRINT.TITLE")}</h2>
          <DstTableSearch
            data={sprints[0]?.users || []}
            searchableColumns={["last_name", "first_name", "email"]}
            onSearch={setFilteredData}
          />
        </div>
        <DstButton
          btnImageBefore={<i className="dst-icon-edit-02 me-2 align-text-bottom" />}
          value={translate(language, "PAGES.COHORT.SPRINT.BUTTON_MANAGE")}
          variant="light"
          clickFunction={() => {
            setShowPopup(true);
            fetchModules(setModules, setLoadingModules);
          }}
        />
      </div>
      <div className="cohort-sprint-table-container">
        {sprints.length > 0 ? (
          <DstTabs
            activeKey={selectedSprint?.toString() || ""}
            onSelect={(key) => setSelectedSprint(parseInt(key || "", 10))}
            variant="primary"
            tabsData={sprints.map((sprintData, index) => ({
              id: String(sprintData.sprint.id),
              key: `tab-${sprintData.sprint.id}`,
              title: (
                <React.Fragment>
                  <span className="badge bg-primary me-2">S{index + 1}</span>
                  {sprintData.sprint.name}
                  {isPast(sprintData.sprint.dt_end) && (
                    <i className="dst-icon-check-circle-broken dark-green-font ms-2 align-text-bottom"></i>
                  )}
                </React.Fragment>
              ),
              content: (
                <DstTable
                  key={`table-${sprintData.sprint.id}`}
                  data={sprintData.users.filter(
                    (user: sprintUser) =>
                      !filteredData || filteredData.map((filteredUser) => filteredUser.email).includes(user.email)
                  )}
                  stylishLine={(line: sprintUser) =>
                    allMandatoryModulesValidated(line.modules_info, sprintData.modules)
                      ? { backgroundColor: "var(--color-medium-green)" }
                      : {}
                  }
                  columns={columns(sprintData.sprint.id)}
                  onRowClick={(event, item) => completeNavigate(event, navigate, `/user/${item.id}`)}
                />
              ),
            }))}
          />
        ) : (
          <div className="mt-3 text-muted">{translate(language, "PAGES.COHORT.SPRINT.NO_SPRINTS_FOUND")}</div>
        )}
      </div>
      <CohortSprintPopup
        language={language}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        existingSprints={sprints}
        loadingModules={loadingModules}
        modules={modules}
        handleSaveSprints={(
          inputValues: string[],
          startDates: string[],
          endDates: string[],
          optionalStatus: any[],
          language: string,
          callback: Function
        ) =>
          saveSprints(cohortId, inputValues, startDates, endDates, optionalStatus, language, () => {
            callback();
            loadSprintData();
          })
        }
      />
    </div>
  );
};

export default CohortSprint;
