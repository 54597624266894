import React, { useEffect, useState } from "react";

import DstButton from "../../../../../components/DstButton/DstButton";
import DstTabsVertical from "../../../../../components/DstTabsVertical/DstTabsVertical";
import Popup from "../../../../../components/Popup/Popup";
import { formatToISODateTime } from "../../../../../services/TimeService";
import translate from "../../../../../services/Translate";
import { sprintModules, sprintObject } from "../CohortSprint.d";
import { buildPopupTabs } from "./CohortSprintPopup.function";

export const handleAddNewSprint = (
  setSprints: Function,
  setInputValues: Function,
  setStartDates: Function,
  setEndDates: Function,
  setOptionalStatus: Function,
  setActiveKey: Function
) => {
  setSprints((prevSprints: sprintObject[]) => {
    const newSprint = {
      sprint: {
        id: `new-${Date.now()}`,
        name: `Sprint ${prevSprints.length + 1}`,
        dt_start: "",
        dt_end: "",
      },
      modules: [],
    };

    setActiveKey(`sprint-${prevSprints.length}`);

    return [...prevSprints, newSprint];
  });
  setInputValues((inputValues: string[]) => [...inputValues, `Sprint ${inputValues.length + 1}`]);
  setStartDates((startDates: string[]) => [...startDates, ""]);
  setEndDates((endDates: string[]) => [...endDates, ""]);
  setOptionalStatus((optionalStatus: any[]) => [...optionalStatus, []]);
};

const CohortSprintPopup = ({
  language,
  showPopup,
  setShowPopup,
  activeKey,
  setActiveKey,
  existingSprints,
  loadingModules,
  modules,
  handleSaveSprints,
}: {
  language: string;
  showPopup: boolean;
  setShowPopup: Function;
  activeKey: string;
  setActiveKey: Function;
  existingSprints: sprintObject[];
  loadingModules: boolean;
  modules: any[];
  handleSaveSprints: Function;
}) => {
  const [isSending, setIsSending] = useState(false);
  const [sprints, setSprints] = useState<sprintObject[]>([]);
  const [inputValues, setInputValues] = useState<string[]>([]);
  const [startDates, setStartDates] = useState<string[]>([]);
  const [endDates, setEndDates] = useState<string[]>([]);
  const [optionalStatus, setOptionalStatus] = useState<any[]>([]);

  useEffect(() => {
    if (existingSprints.length > 0) {
      setSprints(existingSprints);
    }
  }, [existingSprints]);

  useEffect(() => {
    if (sprints.length > 0) {
      setInputValues(sprints.map((sprintData) => sprintData.sprint.name));
      setStartDates(
        sprints.map((sprint) => (sprint.sprint.dt_start ? formatToISODateTime(sprint.sprint.dt_start) : ""))
      );
      setEndDates(sprints.map((sprint) => (sprint.sprint.dt_end ? formatToISODateTime(sprint.sprint.dt_end) : "")));
      setOptionalStatus(
        sprints.map((sprintData) =>
          sprintData.modules.map((moduleData: sprintModules) => ({
            id: moduleData.module,
            name: moduleData.name,
            isOptional: !moduleData.is_mandatory,
          }))
        )
      );
    }
  }, [sprints]);

  return (
    <Popup
      show={showPopup}
      onClose={() => setShowPopup(false)}
      modalTitle={translate(language, "PAGES.COHORT.SPRINT.POPUP.TITLE")}
      removePadding
      size="xl"
      footerContent={
        <DstButton
          value={translate(language, "PAGES.COHORT.SPRINT.POPUP.INFO_SPRINT.BUTTON_SAVE")}
          variant="primary"
          btnClass="px-4"
          loading={isSending}
          disabled={isSending}
          clickFunction={() => {
            setIsSending(true);
            handleSaveSprints(inputValues, startDates, endDates, optionalStatus, language, () => {
              setIsSending(false);
              setShowPopup(false);
            });
          }}
        />
      }
    >
      <DstTabsVertical
        titleIcon={<React.Fragment></React.Fragment>}
        classes="pt-0"
        bodyClass="megium-gray-background"
        activeKey={activeKey}
        onSelect={(key: any) => key && setActiveKey(key)}
        fullHeight
        tabsData={buildPopupTabs(
          language,
          sprints,
          isSending,
          setIsSending,
          inputValues,
          setInputValues,
          optionalStatus,
          setOptionalStatus,
          startDates,
          setStartDates,
          endDates,
          setEndDates,
          loadingModules,
          modules,
          () => {}
        )}
        variant="light"
        TabsSize={{ xl: 3, lg: 4, xs: 3 }}
        customButtonComponent={
          <DstButton
            value={translate(language, "PAGES.COHORT.SPRINT.POPUP.ADD_SPRINT")}
            variant="light"
            backgroundColor="--var(--color-medium-gray)"
            color="var(--color-black)"
            btnClass="w-fit my-3 custom-btn-role m-3"
            btnImageBefore={<i className="dst-icon-plus-circle me-2" />}
            clickFunction={() =>
              handleAddNewSprint(
                setSprints,
                setInputValues,
                setStartDates,
                setEndDates,
                setOptionalStatus,
                setActiveKey
              )
            }
          />
        }
      />
    </Popup>
  );
};

export default CohortSprintPopup;
