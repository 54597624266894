import ApiService from "../../../../../services/ApiService";
import translate from "../../../../../services/Translate";

export const addUsersFlags = (
  language: string,
  cohortId: string,
  userIds: number[],
  flagIds: number[],
  setIsAdding: Function,
  successCallback?: Function
) => {
  setIsAdding(true);
  ApiService.post(`cohorts/${cohortId}/users/flags/batch`, {
    user_ids: userIds,
    flag_ids: flagIds,
  })
    .then((_) => {
      alert(translate(language, "PAGES.COHORT.USERS.MODALS.ADD_FLAGS_TO_USERS.ALERTS.SUCCESS"));
      successCallback?.();
    })
    .catch((error) => {
      console.error("Error updating user flags", error);
      alert(translate(language, "PAGES.COHORT.USERS.MODALS.ADD_FLAGS_TO_USERS.ALERTS.ERROR"));
    })
    .finally(() => setIsAdding(false));
};

export const fetchFlags = (language: string, setFlags: Function, setIsFlagsLoading: Function) => {
  setIsFlagsLoading(true);
  ApiService.get("flags")
    .then((resp) => setFlags(resp.data.flags))
    .catch((error) => {
      console.error("Error fetching flags", error);
      alert(translate(language, "PAGES.COHORT.USERS.MODALS.ADD_FLAGS_TO_USERS.ALERTS.FETCH_FLAGS_ERROR"));
    })
    .finally(() => setIsFlagsLoading(false));
};
