import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";
import { MeetingImportStructuredData } from "./MeetingImport.d";

/**
 * Take payload from CSV, remove empty values, rename bad keys, and type correctly the existing values
 * @param data Input payload from CSV
 * @returns Payload for API request to create several meetings
 */
const _cleanDataBeforeSubmitting = (data: Array<MeetingImportStructuredData>) =>
  data.map((line) => ({
    ...line,
    animator: undefined,
    animators: line.animator?.trim().length
      ? line.animator.split(",").map((animator_email) => animator_email.trim())
      : undefined,
    cohort_id: undefined,
    cohort_ids: line.cohort_id?.trim().length
      ? line.cohort_id.split(",").map((cohort_id: string) => +cohort_id.trim())
      : undefined,
    project_id: line.project_id ? +line.project_id : undefined,
    custom_meeting_link: line.custom_meeting_link.trim() || undefined,
    date: undefined,
    duration: +line.duration,
    start: undefined,
    start_date: line.date,
    start_hour: line.start,
  }));

export const createImportedMeetings = (
  data: Array<MeetingImportStructuredData>,
  language: string,
  setSubmitting: Function
) => {
  setSubmitting(true);
  ApiService.post("meetings/batch", { meetings: _cleanDataBeforeSubmitting(data) })
    .then(() => alert("Meetings created successfully."))
    .catch((err) => {
      console.error("An error occurred while creating meetings in batch", err);
      alert(translate(language, "ERROR.API_ERROR"));
    })
    .finally(() => setSubmitting(false));
};
