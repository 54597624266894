import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import DstButton from "../../../../components/DstButton/DstButton";
import DstTable from "../../../../components/DstTable/DstTable";
import Popup from "../../../../components/Popup/Popup";
import { useSession } from "../../../../contexts/SessionContext";
import translate from "../../../../services/Translate";
import { Attendee } from "../../Meeting.d";
import {
  fetchAttendanceSheet,
  fetchMeetingAttendance,
  generateAttendanceLink,
  lockAttendance,
} from "../../Meeting.function";

const Attendance = ({ meetingId, edusignLocked, edusignCourseId, refreshMeeting }: any) => {
  const [attendance, setAttendance] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedAttendees, setSelectedAttendees] = useState<number>(0);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const { language } = useSession();

  useEffect(() => {
    if (meetingId && edusignLocked) {
      fetchMeetingAttendance(
        meetingId,
        (data: any[]) => {
          const updatedData = data.map((elem) => ({
            ...elem,
            id: elem.id || elem.user_id,
          }));

          setAttendance(updatedData);
          setLoading(false);
        },
        setLoading
      );
    }
  }, [meetingId, edusignLocked, language]);

  const columns = [
    { key: "first_name", name: translate(language, "PAGES.MEETING.ATTENDANCE.COLUMN_HEADER.FIRST_NAME") },
    { key: "last_name", name: translate(language, "PAGES.MEETING.ATTENDANCE.COLUMN_HEADER.LAST_NAME") },
    { key: "email", name: translate(language, "PAGES.MEETING.ATTENDANCE.COLUMN_HEADER.USERNAME") },
    {
      key: "cohorts",
      name: translate(language, "PAGES.MEETING.ATTENDANCE.COLUMN_HEADER.COHORT"),
      render: (item: Attendee) =>
        item.related_cohorts.length > 0 ? item.related_cohorts.map((c: any) => c.name).join(", ") : "No Cohort",
    },
    {
      key: "has_signed",
      name: translate(language, "PAGES.MEETING.ATTENDANCE.COLUMN_HEADER.EDUSIGN_STATUS"),
      render: (item: Attendee) => {
        return item.has_signed ? (
          <span className="text-success">
            <i className="dst-icon-check me-2" />
            {translate(language, "PAGES.MEETING.ATTENDANCE.COLUMN_BODY.EDUSIGN_STATUS.SIGNED")}
          </span>
        ) : (
          <span className="orange-font">
            <i className="dst-icon-alert-square me-2" />
            {translate(language, "PAGES.MEETING.ATTENDANCE.COLUMN_BODY.EDUSIGN_STATUS.NOT_SIGNED")}
          </span>
        );
      },
    },
    {
      key: "attended",
      name: translate(language, "PAGES.MEETING.ATTENDANCE.COLUMN_HEADER.ATTENDED"),
      render: (item: Attendee) => {
        switch (item.attended) {
          case true:
            return translate(language, "PAGES.MEETING.ATTENDANCE.COLUMN_BODY.ATTENDED.ATTENDED");
          case false:
            return translate(language, "PAGES.MEETING.ATTENDANCE.COLUMN_BODY.ATTENDED.ABSENT");
          case "Justified absence":
            return translate(language, "PAGES.MEETING.ATTENDANCE.COLUMN_BODY.ATTENDED.JUSTIFIED_ABSENCE");
          case "Forgotten signature":
            return translate(language, "PAGES.MEETING.ATTENDANCE.COLUMN_BODY.ATTENDED.FORGOTTEN_SIGNATURE");
          default:
            return "N/A";
        }
      },
    },
  ];

  return (
    <div>
      <Row className="mb-3 d-flex justify-content-between">
        <Col className="d-flex">
          <DstButton
            value={translate(language, "PAGES.MEETING.ATTENDANCE.BUTTONS.GET_ATTENDANCE_LINK")}
            btnImageBefore={<i className="dst-icon-link-01 me-2" />}
            variant="light"
            btnClass="me-2 my-1"
            clickFunction={() =>
              edusignCourseId
                ? setShowWarningModal(true)
                : generateAttendanceLink(meetingId, language, setLoading, refreshMeeting)
            }
          />
          {edusignLocked && (
            <DstButton
              value={translate(language, "PAGES.MEETING.ATTENDANCE.BUTTONS.ATTENDANCE_SHEET")}
              btnImageBefore={<i className="dst-icon-download-02 me-2" />}
              variant="secondary"
              btnClass="my-1"
              clickFunction={() => fetchAttendanceSheet(meetingId, language)}
              mock={loading}
              btnWidth="140px"
              btnHeight="auto"
            />
          )}
        </Col>
        {selectedAttendees > 0 && (
          <Col md className="text-md-end">
            <span className="xs-regular blue-font me-2">
              {selectedAttendees} {translate(language, "PAGES.MEETING.ATTENDANCE.SELECTED_ATTENDEES")}
            </span>
            <DstButton
              value={translate(language, "PAGES.MEETING.ATTENDANCE.BUTTONS.DELETE_FROM_ATTENDANCE")}
              variant="outline-danger border-0"
              btnClass="tinted-red-background me-2 my-1"
              btnImageBefore={<i className="dst-icon-trash-03 me-2" />}
              clickFunction={() => alert("WIP")}
            />
            <DstButton
              value={translate(language, "PAGES.MEETING.ATTENDANCE.BUTTONS.JUSTIFIED_ABSENCE")}
              variant="light"
              btnClass="me-2 my-1 border-0"
              btnImageBefore={<i className="dst-icon-file-check-02 me-2" />}
              clickFunction={() => alert("WIP")}
            />
            <DstButton
              value={translate(language, "PAGES.MEETING.ATTENDANCE.BUTTONS.ATTENDED_THE_MEETING")}
              variant="success"
              btnClass="my-1 border-0 medium-green-background"
              btnImageBefore={<i className="dst-icon-clipboard-check me-2" />}
              clickFunction={() => alert("WIP")}
            />
          </Col>
        )}
      </Row>
      <DstTable
        columns={columns}
        data={attendance}
        loading={loading}
        itemsPerPage={10}
        pagination
        striped
        selectable
        selectedItems={selectedRows}
        onRowSelect={(selected: any[]) => {
          setSelectedAttendees(selected.length);
          setSelectedRows(selected);
        }}
      />
      {!edusignLocked && (
        <div className="mt-3 text-center">
          <DstButton
            value={translate(language, "PAGES.MEETING.ATTENDANCE.BUTTONS.LOCK_ATTENDANCE")}
            variant="danger"
            clickFunction={() =>
              !edusignCourseId
                ? alert(translate(language, "PAGES.MEETING.ERRORS.GENERATE_ATTENDANCE_FIRST"))
                : lockAttendance(meetingId, language, setLoading, refreshMeeting)
            }
          />
        </div>
      )}
      <Popup
        show={showWarningModal}
        onClose={() => setShowWarningModal(false)}
        modalTitle={translate(language, "PAGES.MEETING.ATTENDANCE.MODAL_LINK_ALREADY_GENERATED.TITLE")}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: translate(language, "PAGES.MEETING.ATTENDANCE.MODAL_LINK_ALREADY_GENERATED.EXPLAIN"),
          }}
        />
      </Popup>
    </div>
  );
};

export default Attendance;
