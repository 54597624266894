import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";

export const fetchMeetingTypes = (setMeetingTypes: Function, setLoading: Function, language: string) => {
  setLoading(true);
  ApiService.get("meetings/meeting_types")
    .then((response) => {
      setMeetingTypes(response.data.meeting_types);
    })
    .catch((err) => {
      console.error("Error fetching meeting types:", err);
      alert(translate(language, "PAGES.MEETING_TYPES.ERROR.FETCH"));
    })
    .finally(() => {
      setLoading(false);
    });
};
