import { useEffect, useState } from "react";
import React from "react";
import { Col, FormCheck, FormControl, FormLabel, FormSelect, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import CheckIcon from "../../components/DstIcons/CheckIcon";
import CrossIcon from "../../components/DstIcons/CrossIcon";
import DstTabs from "../../components/DstTabs/DstTabs";
import Popup from "../../components/Popup/Popup";
import UserBlock from "../../components/UserBlock/UserBlock";
import { useSession } from "../../contexts/SessionContext";
import { Note } from "../../models/Note";
import { TabData } from "../../models/TabModel";
import ApiService from "../../services/ApiService";
import { getUTCTime } from "../../services/TimeService";
import translate from "../../services/Translate";
import { truncateString } from "../../services/UtilityService";
import { displayDate, fetchEditionElements, loginAs, logout, updateUser } from "./User.function";
import "./User.scss";
import UserActivity from "./components/UserActivity/UserActivity";
import UserCertification from "./components/UserCertification/UserCertification";
import UserDocuments from "./components/UserDocuments/UserDocuments";
import UserEmails from "./components/UserEmails/UserEmails";
import UserExams from "./components/UserExams/UserExams";
import UserMeetings from "./components/UserMeetings/UserMeetings";
import UserMessages from "./components/UserMessages/UserMessages";
import UserNotes from "./components/UserNotes/UserNotes";
import UserOverview from "./components/UserOverview/UserOverView";
import UserProjects from "./components/UserProjects/UserProjects";

const User = () => {
  const { language, user_id, email } = useSession();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState({
    user: false,
    cohorts: false,
    projects: false,
    lessons: false,
    emails: false,
    documents: false,
    flags: false,
    meetings: false,
    exams: false,
    certifs: false,
    notes: false,
    messages: false,
  });
  // Core
  const [showEmailModale, setShowEmailModale] = useState(false);
  const [emailModaleContent, setEmailModaleContent] = useState("");
  const [userData, setUserData] = useState({});
  const params = useParams();

  const refreshUserCertifications = async () => {
    try {
      const response = await ApiService.get(`users/${params.user_id}/certifications`);
      setUserData((prevData) => ({
        ...prevData,
        user_certifs: response.data.certifications,
      }));
      setLoaded((prevLoaded) => ({
        ...prevLoaded,
        certifs: true,
      }));
    } catch (error) {
      console.error("Failed to fetch certifications", error);
    }
  };

  // Edition
  const [showEditionModale, setShowEditionModale] = useState(false);
  const [cohorts, setCohorts] = useState<any>([]);
  const [flags, setFlags] = useState<any>([]);
  const [editedUserData, setEditedUserData] = useState({});
  const [modaleLoaded, setModaleLoaded] = useState(false);
  const [editionLoading, setEditionLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("overview");
  const [activeModalKey, setActiveModalKey] = useState("information");
  const [activeNote, setActiveNote] = useState<Note | null>(null);

  const _build_tabs = (user_data: any) => {
    const tabsData: TabData[] = [
      {
        id: "overview",
        title: translate(language, "PAGES.USER.TAB.OVERVIEW"),
        content: (
          <UserOverview
            language={language}
            setActiveKey={setActiveKey}
            user_data={user_data}
            lessonsLoaded={loaded.lessons}
            notesLoaded={loaded.notes}
            certifsLoaded={loaded.certifs}
            setUserData={setUserData}
            setActiveNote={setActiveNote}
            setLoaded={setLoaded}
          />
        ),
      },
      {
        id: "activity",
        title: translate(language, "PAGES.USER.TAB.ACTIVITY"),
        content: (
          <UserActivity
            language={language}
            userName={user_data.first_name}
            userId={user_id!}
            userModules={user_data.user_modules}
          />
        ),
      },
      {
        id: "certif-exams",
        title: "Certif & Exams",
        content: (
          <React.Fragment>
            <UserCertification
              language={language}
              user_data={user_data}
              loaded={loaded.certifs}
              refreshUserCertifications={refreshUserCertifications}
            />
            <UserExams language={language} user_data={user_data} loaded={loaded.exams} />
          </React.Fragment>
        ),
      },
      {
        id: "emails",
        title: translate(language, "PAGES.USER.TAB.EMAIL"),
        content: (
          <UserEmails
            user_data={user_data}
            loaded={loaded.emails}
            language={language}
            showEmailModal={showEmailModale}
            setShowEmailModal={setShowEmailModale}
            emailModalContent={emailModaleContent}
            setEmailModalContent={setEmailModaleContent}
          />
        ),
      },
      {
        id: "documents",
        title: translate(language, "PAGES.USER.TAB.DOCUMENT"),
        content: <UserDocuments user_data={user_data} language={language} loaded={loaded.documents} />,
      },
      {
        id: "meetings",
        title: translate(language, "PAGES.USER.TAB.MEETING"),
        content: <UserMeetings language={language} user_data={user_data} loaded={loaded.meetings} />,
      },
      {
        id: "projects",
        title: translate(language, "PAGES.USER.TAB.PROJECT"),
        content: <UserProjects language={language} user_data={user_data} loaded={loaded.projects} />,
      },
      {
        id: "notes",
        title: translate(language, "PAGES.USER.TAB.NOTE"),
        content: (
          <UserNotes
            language={language}
            user_data={user_data}
            setUserData={setUserData}
            user_email={email!}
            loaded={loaded.notes}
            activeNote={activeNote}
            setActiveNote={setActiveNote}
          />
        ),
      },
      {
        id: "messages",
        title: translate(language, "PAGES.USER.TAB.MESSAGES"),
        content: (
          <div className="w-100">
            <UserMessages language={language} user_data={user_data} loaded={loaded.messages} />
          </div>
        ),
      },
    ];
    return tabsData;
  };

  const addCohortToUser = () => {
    setEditedUserData((userData: { user_cohorts: any[] }) => ({
      ...userData,
      user_cohorts: [...userData.user_cohorts, {}],
    }));
  };

  const deleteCohortToUser = (index: number) => {
    setEditedUserData((userData: { user_cohorts: any[] }) => {
      let new_user_cohorts = [...userData.user_cohorts];
      new_user_cohorts.splice(index, 1);
      return {
        ...userData,
        user_cohorts: new_user_cohorts,
      };
    });
  };

  // TODO: Implements with function fetcher
  // TODO: Implements logical with key -> functions optimized
  // TODO: Fetch modules on cohort edition
  const refreshUserBlock = () => {
    setLoaded((loaded: any) => ({ ...loaded, cohorts: false, projects: false }));
    ApiService.get(`users/${params.user_id}`)
      .then((resp) => {
        const user = resp.data.user;
        setUserData((userData) => ({
          ...userData,
          ...user,
        }));
        setLoaded((loaded) => ({ ...loaded, user: true }));
      })
      .catch((err) => console.error("An error occurred for fetching user's global", err));
    ApiService.get(`users/${params.user_id}/cohorts`)
      .then((resp) => {
        const user_cohorts = resp.data.cohorts;
        setUserData((userData) => ({
          ...userData,
          user_cohorts,
        }));
        setLoaded((loaded) => ({ ...loaded, cohorts: true }));
      })
      .catch((err) => console.error("An error occurred for fetching user's cohorts", err));
    ApiService.get(`users/${params.user_id}/projects`)
      .then((resp) => {
        const user_projects = resp.data.projects;
        setUserData((userData) => ({
          ...userData,
          user_projects,
        }));
        setLoaded((loaded) => ({ ...loaded, projects: true }));
      })
      .catch((err) => console.error("An error occurred for fetching user's projects", err));
    ApiService.get(`users/${params.user_id}/flags`)
      .then((resp) => {
        const user_flags = resp.data.user_flags;
        setUserData((userData) => ({
          ...userData,
          user_flags,
        }));
        setLoaded((loaded) => ({ ...loaded, flags: true }));
      })
      .catch((err) => console.error("An error occurred for fetching user's flags", err));
  };

  const _build_edition_modale_tabs = (user_data: any) => {
    const tabsData: TabData[] = [
      {
        id: "information",
        title: translate(language, "PAGES.USER.USER_MODAL.INFORMATION"),
        content: (
          <div className="xs-regular px-3">
            <h3 className="mb-3 m-regular border-bottom">{translate(language, "PAGES.USER.USER_MODAL.TITLE")}</h3>
            <Row className="py-3">
              <Col className="border-end">
                <Row className="mb-3">
                  <Col>
                    <FormLabel htmlFor="user-first-name">
                      {translate(language, "PAGES.USER.USER_MODAL.FIRST_NAME")}
                    </FormLabel>
                    <FormControl
                      className="xs-regular"
                      id="user-first-name"
                      type="text"
                      value={user_data.first_name}
                      onChange={(event) =>
                        setEditedUserData((previous_user_data) => ({
                          ...previous_user_data,
                          first_name: event.target.value,
                        }))
                      }
                    />
                  </Col>
                  <Col>
                    <FormLabel htmlFor="user-last-name">
                      {translate(language, "PAGES.USER.USER_MODAL.LAST_NAME")}
                    </FormLabel>
                    <FormControl
                      className="xs-regular"
                      id="user-last-name"
                      type="text"
                      value={user_data.last_name}
                      onChange={(event) =>
                        setEditedUserData((previous_user_data) => ({
                          ...previous_user_data,
                          last_name: event.target.value,
                        }))
                      }
                    />
                  </Col>
                </Row>
                <FormLabel htmlFor="user-email">{translate(language, "PAGES.USER.USER_MODAL.EMAIL")}</FormLabel>
                <FormControl
                  className="xs-regular"
                  id="user-email"
                  type="text"
                  value={user_data.email}
                  onChange={(event) =>
                    setEditedUserData((previous_user_data) => ({ ...previous_user_data, email: event.target.value }))
                  }
                />
              </Col>
              <Col>
                <Row>
                  <Col>
                    <FormLabel htmlFor="user-start-date">
                      {translate(language, "PAGES.USER.USER_MODAL.START_DATE")}
                    </FormLabel>
                    <FormControl
                      className="xs-regular"
                      id="user-start-date"
                      type="datetime-local"
                      value={displayDate(user_data.dt_start)}
                      onChange={(event) => {
                        setEditedUserData((previous_user_data) => ({
                          ...previous_user_data,
                          dt_start: getUTCTime(event.target.value),
                        }));
                      }}
                    />
                  </Col>
                  <Col>
                    <FormLabel htmlFor="user-end-date">
                      {translate(language, "PAGES.USER.USER_MODAL.END_DATE")}
                    </FormLabel>
                    <FormControl
                      className="xs-regular"
                      id="user-end-date"
                      type="datetime-local"
                      value={displayDate(user_data.dt_end)}
                      onChange={(event) =>
                        setEditedUserData((previous_user_data) => ({
                          ...previous_user_data,
                          dt_end: getUTCTime(event.target.value),
                        }))
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ),
      },
      {
        id: "cohorts",
        title: translate(language, "PAGES.USER.USER_MODAL.COHORT"),
        content: (
          <div className="xs-regular px-3">
            <h3 className="mb-3 m-regular border-bottom">{translate(language, "PAGES.USER.USER_MODAL.COHORT")}</h3>
            <div>
              {user_data.user_cohorts.map((user_cohort: any, index: number) => (
                <div className="xs-regular" key={`div-cohort-${index}`}>
                  {index > 0 && <hr />}
                  <div className="d-flex">
                    <FormSelect
                      className="xs-regular w-fit me-2"
                      name={`user-cohort-${index}`}
                      onChange={(event) => {
                        setEditedUserData((userData: any) => {
                          let new_user_cohorts = [...userData.user_cohorts];

                          if (event.target.value !== "") {
                            new_user_cohorts[index] = cohorts.find((cohort: any) => cohort.id === +event.target.value);
                          } else {
                            new_user_cohorts[index] = null;
                          }
                          return {
                            ...userData,
                            user_cohorts: new_user_cohorts,
                          };
                        });
                      }}
                      value={user_cohort.id || ""}
                    >
                      {cohorts
                        .sort((a: any, b: any) => a.name.localeCompare(b.name))
                        .map((cohort: any, index: number) => (
                          <option key={`select-cohort-${index}`} value={cohort.id}>
                            {cohort.name}
                          </option>
                        ))}
                    </FormSelect>
                    <DstButton
                      value="Delete cohort"
                      variant="danger"
                      clickFunction={() => deleteCohortToUser(index)}
                      disabled={(editedUserData as { user_cohorts: any[] }).user_cohorts.length === 1}
                    />
                  </div>
                  <Row className="my-3">
                    <Col>
                      <h3 className="m-regular">{translate(language, "PAGES.USER.USER_MODAL.FORMAT")}</h3>
                      <p className="silver-gray-font">{user_cohort.intensity}</p>
                    </Col>
                    <Col>
                      <h3 className="m-regular">{translate(language, "PAGES.USER.USER_MODAL.TIME")}</h3>
                      <p className="silver-gray-font">
                        {new Date(user_cohort.dt_start).toLocaleDateString()} -{" "}
                        {new Date(user_cohort.dt_end).toLocaleDateString()}
                      </p>
                    </Col>
                    <Col>
                      <h3 className="m-regular">{translate(language, "PAGES.USER.USER_MODAL.WORKSPACE")}</h3>
                      <p className="silver-gray-font">{user_cohort.workspace_url}</p>
                    </Col>
                    <Col>
                      <h3 className="m-regular">{translate(language, "PAGES.USER.USER_MODAL.NEED_PROJECT")}</h3>
                      <p className="silver-gray-font">{user_cohort.need_project ? <CheckIcon /> : <CrossIcon />}</p>
                    </Col>
                    <Col>
                      <h3 className="m-regular">{translate(language, "PAGES.USER.USER_MODAL.LANGUAGE")}</h3>
                      <p className="silver-gray-font">{user_cohort.language}</p>
                    </Col>
                  </Row>
                </div>
              ))}
              <DstButton
                variant="secondary"
                value={translate(language, "PAGES.USER.USER_MODAL.ADD_COHORT")}
                clickFunction={addCohortToUser}
              />
            </div>
          </div>
        ),
      },
      {
        id: "badges",
        title: translate(language, "PAGES.USER.USER_MODAL.BADGE"),
        content: (
          <div className="xs-regular px-3">
            <h3 className="mb-3 m-regular border-bottom">{translate(language, "PAGES.USER.USER_MODAL.FLAGS")}</h3>
            <div className="py-3">
              {flags.map((flag: any, index: number) => (
                <FormCheck
                  key={`flag-${index}`}
                  id={`flag-${flag.id}`}
                  type="checkbox"
                  inline
                  label={<label htmlFor={`flag-${flag.id}`}>{flag.name}</label>}
                  checked={user_data.user_flags?.map((user_flag: any) => user_flag.name).includes(flag.name)}
                  onChange={(event) => {
                    setEditedUserData((previous_user_data) => ({
                      ...previous_user_data,
                      user_flags: event.target.checked
                        ? [...(previous_user_data as any)?.user_flags, flag]
                        : [...(previous_user_data as any)?.user_flags.filter((element: any) => element.id !== flag.id)],
                    }));
                  }}
                />
              ))}
            </div>
            <h3 className="mb-3 m-regular border-bottom">{translate(language, "PAGES.USER.USER_MODAL.DISABILITY")}</h3>
            <div className="py-3">
              <FormCheck
                type="checkbox"
                id="badge-extratime"
                label={
                  <label htmlFor="badge-extratime">{translate(language, "PAGES.USER.USER_BLOCK.EXTRA_TIME")}</label>
                }
                checked={user_data.has_extra_time}
                onChange={(event) =>
                  setEditedUserData((previous_user_data) => ({
                    ...previous_user_data,
                    has_extra_time: event.target.checked,
                  }))
                }
              />
            </div>
          </div>
        ),
      },
    ];
    return tabsData;
  };

  useEffect(() => {
    (async () => {
      if (Object.keys(userData).length) {
        if (+params.user_id! !== +(userData as any).id)
          setLoaded({
            user: false,
            cohorts: false,
            projects: false,
            lessons: false,
            emails: false,
            documents: false,
            flags: false,
            meetings: false,
            exams: false,
            certifs: false,
            notes: false,
            messages: false,
          });
        else {
          return;
        }
      }
      const now = Date.now();
      ApiService.get(`users/${params.user_id}`)
        .then((resp) => {
          const user = resp.data.user;
          setUserData((userData) => ({
            ...userData,
            ...user,
          }));
        })
        .catch((err) => console.error("Error fetching user:", err))
        .finally(() => {
          setLoaded((loaded) => ({ ...loaded, user: true }));
        });
      ApiService.get(`users/${params.user_id}/cohorts`)
        .then((resp) => {
          const user_cohorts = resp.data.cohorts;
          setUserData((userData) => ({
            ...userData,
            user_cohorts,
          }));
          setLoaded((loaded) => ({ ...loaded, cohorts: true }));
        })
        .catch((err) => console.error("An error occurred for fetching user's cohorts", err));
      ApiService.get(`users/${params.user_id}/projects`)
        .then((resp) => {
          const user_projects = resp.data.projects;
          setUserData((userData) => ({
            ...userData,
            user_projects,
          }));
          setLoaded((loaded) => ({ ...loaded, projects: true }));
        })
        .catch((err) => console.error("An error occurred for fetching user's projects", err));
      ApiService.get(`users/${params.user_id}/lessons`)
        .then((resp) => {
          const user_lessons = resp.data.return;
          setUserData((userData) => ({
            ...userData,
            user_sprints: user_lessons.sprints,
            user_modules: user_lessons.user_modules,
          }));
          setLoaded((loaded) => ({ ...loaded, lessons: true }));
        })
        .catch((err) => console.error("An error occurred for fetching user's lessons", err));
      ApiService.get(`users/${params.user_id}/emails`)
        .then((resp) => {
          const user_emails = resp.data.emails;
          setUserData((userData) => ({
            ...userData,
            user_emails,
          }));
          setLoaded((loaded) => ({ ...loaded, emails: true }));
        })
        .catch((err) => console.error("An error occurred for fetching user's emails", err));
      ApiService.get(`users/${params.user_id}/evaluation_attempts?fetch_corrector=true`)
        .then((resp) => {
          const user_exams = resp.data.evaluation_attempts;
          setUserData((userData) => ({
            ...userData,
            user_exams,
          }));
          setLoaded((loaded) => ({ ...loaded, exams: true }));
        })
        .catch((err) => console.error("An error occurred for fetching user's exams", err));
      ApiService.get(`users/${params.user_id}/certifications`)
        .then((resp) => {
          const user_certifs = resp.data.certifications;
          setUserData((userData) => ({
            ...userData,
            user_certifs,
          }));
          setLoaded((loaded) => ({ ...loaded, certifs: true }));
        })
        .catch((err) => console.error("An error occurred for fetching user's certifications", err));
      ApiService.get(`users/${params.user_id}/documents`)
        .then((resp) => {
          const user_documents = resp.data.documents;
          setUserData((userData) => ({
            ...userData,
            user_documents,
          }));
          setLoaded((loaded) => ({ ...loaded, documents: true }));
        })
        .catch((err) => console.error("An error occurred for fetching user's documents", err));
      ApiService.get(`users/${params.user_id}/flags`)
        .then((resp) => {
          const user_flags = resp.data.user_flags;
          setUserData((userData) => ({
            ...userData,
            user_flags,
          }));
          setLoaded((loaded) => ({ ...loaded, flags: true }));
        })
        .catch((err) => console.error("An error occurred for fetching user's flags", err));
      ApiService.get(`users/${params.user_id}/notes?fetch_author=true`)
        .then((resp) => {
          const user_notes = resp.data.notes;
          setUserData((userData) => ({
            ...userData,
            user_notes,
          }));
          setLoaded((loaded) => ({ ...loaded, notes: true }));
        })
        .catch((err) => console.error("An error occurred for fetching user's notes", err));
      ApiService.get(`users/${params.user_id}/meetings?fetch_types=true&order=asc`)
        .then((resp) => {
          const user_meetings = resp.data.meetings.map((meeting: any) => ({
            ...meeting,
            start_time: new Date(meeting.dt_start),
            end_time: new Date(meeting.dt_end),
            is_future: new Date(meeting.dt_start).getTime() > now,
            meeting_type: meeting.meeting_type,
          }));
          setUserData((userData) => ({
            ...userData,
            user_meetings,
          }));
          setLoaded((loaded) => ({ ...loaded, meetings: true }));
        })
        .catch((err) => console.error("An error occurred for fetching user's meetings", err));
      ApiService.get(`/users/${params.user_id}/messages`)
        .then((resp) => {
          const user_messages = resp.data.messages.map((message: any) => ({
            id: message.id.toString(),
            author: message.author_name,
            author_id: message.author_id,
            email: message.author_email,
            date: message.dt_added,
            fullContent: message.content,
            content: truncateString(message.content, 100, true),
            dt_seen: message.dt_seen,
          }));
          setUserData((userData) => ({
            ...userData,
            user_messages: user_messages,
          }));
          setLoaded((loaded) => ({ ...loaded, messages: true }));
        })
        .catch((err) => console.error("An error occurred for fetching user's messages", err));
    })();
  }, [params, language, userData]);

  if (loaded.user && Object.keys(userData).length === 0) {
    return (
      <div className="text-center mt-3">
        <h2>{translate(language, "PAGES.USER.USER_NOT_FOUND.TITLE")}</h2>
        <DstButton
          value={translate(language, "PAGES.USER.USER_NOT_FOUND.BUTTON")}
          clickFunction={() => navigate("/users")}
        />
      </div>
    );
  }

  return (
    <div className="d-flex">
      <UserBlock
        edit
        editFunction={() => {
          setShowEditionModale(true);
          setEditedUserData({ ...userData });
          fetchEditionElements(setCohorts, setFlags, () => setModaleLoaded(true));
        }}
        loginAsFunction={loginAs(params.user_id!)}
        logoutFunction={logout(params.user_id!)}
        mock={!(loaded.user && loaded.flags && loaded.cohorts)}
        user={userData}
        language={language}
        refreshUserCertifications={refreshUserCertifications}
      />

      <DstTabs
        tabsData={_build_tabs(userData)}
        variant="primary"
        classes="ps-3 flex-grow-1 user-tabs"
        activeKey={activeKey}
        onSelect={(tabKey: string | null) => setActiveKey(tabKey!)}
      />

      {loaded.user && (
        <Popup
          show={showEditionModale}
          onClose={() => setShowEditionModale(false)}
          modalTitle={`Edit ${(userData as any)?.first_name} ${(userData as any)?.last_name}`}
          size="xl"
          fullscreen="lg-down"
          scrollable={true}
        >
          {modaleLoaded ? (
            <React.Fragment>
              <DstTabs
                tabsData={_build_edition_modale_tabs(editedUserData)}
                variant="secondary"
                activeKey={activeModalKey}
                onSelect={(tabKey: string | null) => setActiveModalKey(tabKey!)}
              />
              <DstButton
                btnClass="mt-3 mx-auto d-block"
                value={translate(language, "PAGES.USER.USER_MODAL.CONFIRM")}
                loading={editionLoading}
                disabled={JSON.stringify(userData) === JSON.stringify(editedUserData)}
                clickFunction={() => updateUser(userData, editedUserData, setEditionLoading, refreshUserBlock, user_id)}
              />
            </React.Fragment>
          ) : (
            <Spinner className="d-block blue-font mb-3 mx-auto" />
          )}
        </Popup>
      )}
    </div>
  );
};

export default User;
