import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";
import { Animator, Organizer } from "./Meeting.d";

export const fetchMeeting = (meetingId: string, language: string, setMeeting: Function, setLoaded: Function) => {
  setLoaded(false);
  ApiService.get(`meetings/${meetingId}`)
    .then((response) => {
      setMeeting(response.data.meeting);
    })
    .catch((err) => {
      console.error("Error fetching meeting data:", err);
    })
    .finally(() => setLoaded(true));
};

export const updateMeeting = (
  meetingId: string,
  meetingData: any,
  language: string,
  setShowEditModal: Function,
  setIsLoading: Function
) => {
  setIsLoading(true);
  ApiService.put(`meetings/${meetingId}`, meetingData)
    .then(() => {
      alert(translate(language, "PAGES.MEETING.SUCCESS.EDITED_MEETING"));
      setShowEditModal(false);
    })
    .catch((err) => {
      console.error("Error updating meeting:", err);
      alert(translate(language, "PAGES.MEETING.ERRORS.EDITED_MEETING"));
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const deleteMeeting = (meetingId: string, language: string, setIsLoading: Function, callback?: Function) => {
  setIsLoading(true);
  ApiService.delete(`meetings/${meetingId}`)
    .then((_) => {
      callback?.();
    })
    .catch((err) => {
      console.error("Error deleting meeting:", err);
      alert(translate(language, "PAGES.MEETING.ERRORS.DELETE_MEETING"));
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const getRessources = (
  setOrganizers: Function,
  setAnimators: Function,
  language: string,
  callback?: Function
) => {
  ApiService.get("meetings/resources")
    .then((response) => {
      const organizersWithId = response.data.meeting_staff_and_programs.organizers.map(
        (organizer: Organizer, index: number) => ({
          ...organizer,
          id: index,
        })
      );
      const animatorsWithId = response.data.meeting_staff_and_programs.animators.map(
        (animator: Animator, index: number) => ({
          ...animator,
          id: index,
        })
      );
      setOrganizers(organizersWithId);
      setAnimators(animatorsWithId);
      callback?.();
    })
    .catch((error) => {
      console.error("Failed to get animators and organizers:", error);
      alert(translate(language, "PAGES.MEETING.ERRORS.GET_ADMINS"));
    });
};

export const updateAnimators = (
  meetingId: string,
  updatedAnimators: { username: string; display: string; first_name: string; last_name: string }[],
  setSelectedAnimators: Function,
  generateZoomLink: boolean,
  language: string,
  setIsLoading: Function
) => {
  setIsLoading(true);
  ApiService.put(`meetings/${meetingId}/animators`, {
    animators_email: updatedAnimators.map((animator) => animator.username),
    generate_zoom_link: generateZoomLink,
  })
    .then(() => {
      setSelectedAnimators(
        updatedAnimators.map((animator) => ({
          username: animator.username,
          display: animator.display || `${animator.first_name} ${animator.last_name}`,
        }))
      );
      alert(translate(language, "PAGES.MEETING.SUCCESS.UPDATED_ANIMATOR"));
    })
    .catch((error) => {
      console.error("Error updating animators:", error);
      alert(translate(language, "PAGES.MEETING.ERRORS.UPDATED_ANIMATOR"));
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const fetchMeetingAttendance = (meetingId: string, setAttendance: Function, setIsLoading: Function) => {
  setIsLoading(true);
  ApiService.get(`meetings/${meetingId}/attendance`)
    .then((response) => {
      setAttendance(response.data.attendance);
    })
    .catch((err) => {
      console.error("Error fetching meeting attendance:", err);
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const generateAttendanceLink = (
  meetingId: string,
  language: string,
  setIsLoading: Function,
  successCallback: Function
) => {
  setIsLoading(true);
  ApiService.post(`meetings/${meetingId}/attendance/link`, {})
    .then(() => {
      alert(translate(language, "PAGES.MEETING.SUCCESS.GENERATE_ATTENDANCE_LINK"));
      successCallback();
    })
    .catch((err) => {
      console.error("Error generating attendance link:", err);
      alert(translate(language, "PAGES.MEETING.ERRORS.GENERATE_ATTENDANCE_LINK"));
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const lockAttendance = (
  meetingId: string,
  language: string,
  setIsLoading: Function,
  successCallback: Function
) => {
  setIsLoading(true);
  ApiService.post(`meetings/${meetingId}/attendance/lock`, {})
    .then(() => {
      alert(translate(language, "PAGES.MEETING.SUCCESS.LOCK_ATTENDANCE"));
      successCallback();
    })
    .catch((err) => {
      console.error("Error locking attendance:", err);
      alert(translate(language, "PAGES.MEETING.ERRORS.LOCK_ATTENDANCE"));
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const fetchAttendanceSheet = (meetingId: string, language: string) => {
  ApiService.post(`meetings/${meetingId}/attendance/document`, {})
    .then((response) => {
      const pdfLink = response.data.document_link.pdf_link;
      if (!pdfLink) {
        throw new Error("PDF link not found in response");
      }

      fetch(pdfLink)
        .then((res) => res.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", `attendance_sheet_${meetingId}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    })
    .catch((err) => {
      console.error("Error fetching attendance sheet:", err);
      alert(translate(language, "PAGES.MEETING.ERRORS.ATTENDANCE_SHEET"));
    });
};

export const getBadgeData = (meeting: any, language: string) => {
  const badges = [
    {
      key: "VIP",
      iconClass: "dst-icon-star-01",
      description: translate(language, "PAGES.MEETING.BADGES.VIP"),
      count: 0,
    },
    {
      key: "B2B",
      iconClass: "dst-icon-image-user-check",
      description: translate(language, "PAGES.MEETING.BADGES.B2B"),
      count: 0,
    },
    {
      key: "Complicated",
      iconClass: "dst-icon-cloud-lightning",
      description: translate(language, "PAGES.MEETING.BADGES.COMPLICATED"),
      count: 0,
    },
    {
      key: "Extra Time",
      iconClass: "dst-icon-clock-stopwatch",
      description: translate(language, "PAGES.MEETING.BADGES.EXTRA_TIME"),
      count: 0,
    },
  ];

  meeting.attendees.forEach((attendee: any) => {
    attendee.flags?.forEach((flag: { name: string }) => {
      const badge = badges.find((b) => b.key === flag.name);
      if (badge) {
        badge.count++;
      }
    });

    if (attendee.extra_time) {
      const extraTimeBadge = badges.find((b) => b.key === "Extra Time");
      if (extraTimeBadge) {
        extraTimeBadge.count++;
      }
    }
  });

  return badges.filter((badge) => badge.count > 0).sort((a, b) => b.count - a.count);
};

export const fetchMeetingNotes = (meetingId: string, language: string, setNotes: Function, setIsLoading: Function) => {
  setIsLoading(true);

  ApiService.get(`meetings/${meetingId}/notes`)
    .then((response) => {
      setNotes(response.data.notes);
    })
    .catch((error) => {
      console.error("Error fetching meeting notes:", error);
      alert(translate(language, "PAGES.MEETING.ERRORS.FETCH_NOTES"));
    })
    .finally(() => {
      setIsLoading(false);
    });
};

export const addNoteToMeeting = (
  meetingId: string,
  content: string,
  language: string,
  setNote: Function,
  setNotes: Function,
  setIsLoading: Function
) => {
  if (!content.trim()) {
    alert(translate(language, "PAGES.MEETING.ERRORS.EMPTY_NOTE"));
    return;
  }

  ApiService.post(`meetings/${meetingId}/notes`, { content })
    .then(() => {
      alert(translate(language, "PAGES.MEETING.SUCCESS.ADD_NOTE"));
      setNote("");

      fetchMeetingNotes(meetingId, language, setNotes, setIsLoading);
    })
    .catch((error) => {
      console.error("Error adding note:", error);
      alert(translate(language, "PAGES.MEETING.ERRORS.ADD_NOTE"));
    });
};
