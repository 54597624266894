import ApiService from "../../services/ApiService";
import translate from "../../services/Translate";
import { DiplomaImportStructuredData } from "./DiplomaImport.d";

export const createImportedDiplomas = (
  data: Array<DiplomaImportStructuredData>,
  language: string,
  setSubmitting: Function,
  sendEmail: boolean
) => {
  setSubmitting(true);
  ApiService.post("diplomas/batch", {
    send_email: sendEmail,
    users: data,
  })
    .then((response) => {
      if (response.data.success) {
        const { skipped_diplomas, succeeded_diplomas, total_diplomas } = response.data.diplomas;

        let alertMessage = translate(language, "PAGES.DIPLOMA_IMPORT.ALERT.SUCCESS_IMPORT");
        alertMessage += `\n${succeeded_diplomas} / ${total_diplomas} ${translate(
          language,
          "PAGES.DIPLOMA_IMPORT.ALERT.SUCCEEDED_DIPLOMAS"
        )}`;

        if (skipped_diplomas.length > 0) {
          alertMessage += `\n\n${skipped_diplomas.length} / ${total_diplomas} ${translate(
            language,
            "PAGES.DIPLOMA_IMPORT.ALERT.SKIPPED_DIPLOMAS"
          )}:`;

          skipped_diplomas.forEach((skipped: DiplomaImportStructuredData) => {
            alertMessage += `\n• ${skipped.email} - ${skipped.cursus}`;
          });
        }

        alert(alertMessage);
      } else {
        console.error("An error occurred while creating diplomas in batch", response.data.error);
        alert(translate(language, "ERROR.API_ERROR"));
      }
    })
    .catch((err) => {
      console.error("An error occurred while creating diplomas in batch", err);
      alert(translate(language, "ERROR.API_ERROR"));
    })
    .finally(() => setSubmitting(false));
};

export const validateImportedDiplomas = (
  data: Array<DiplomaImportStructuredData>,
  language: string,
  setValidatedData: Function,
  setValidating: Function
) => {
  setValidating(true);
  ApiService.post("diplomas/batch/check", {
    users: data,
  })
    .then((response) => {
      if (response.data && response.data.users) {
        setValidatedData(response.data.users);
      }
    })
    .catch((err) => {
      console.error("Error validating CSV data:", err);
      alert(`${translate(language, "PAGES.DIPLOMA_IMPORT.ALERT.ERROR_CSV_VALIDATION")} ${err.message || ""}`);
    })
    .finally(() => setValidating(false));
};
