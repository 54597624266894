import ApiService from "../../../../services/ApiService";
import { calculateEndDate } from "../../../../services/TimeService";
import translate from "../../../../services/Translate";
import { Animator, FormData } from "./CohortMeetings.d";

export const getMeetings = (cohortId: string, setMeetings: Function, language: string, callback?: Function) => {
  ApiService.get(`cohorts/${cohortId}/meetings`)
    .then((response) => {
      setMeetings(response.data.meetings);
      callback?.();
    })
    .catch((error) => {
      console.error("Failed to get meetings:", error);
      setMeetings([]);
      alert(translate(language, "PAGES.COHORT.MEETINGS.ERRORS.GET_MEETINGS"));
    });
};

export const getAdmins = (setAnimators: Function, setPrograms: Function, language: string) => {
  ApiService.get("meetings/resources")
    .then((response) => {
      const animatorsWithId = response.data.meeting_staff_and_programs.animators.map(
        (animator: Animator, index: number) => ({
          ...animator,
          id: index,
        })
      );
      setAnimators(animatorsWithId);
      setPrograms(response.data.meeting_staff_and_programs.programs || []);
    })
    .catch((error) => {
      console.error("Failed to get animators and organizers:", error);
      alert(translate(language, "PAGES.COHORT.MEETINGS.ERRORS.GET_ADMINS"));
    });
};

export const createMeeting = (cohortId: string, formData: FormData, language: string, callback?: Function) => {
  const payload = {
    meeting_type: formData.selectedMeetingType,
    start: formData.startDate,
    end: calculateEndDate(formData.startDate, formData.duration),
    meeting_name: formData.title,
    visibility: formData.selectedVisibility,
    organizer_id: formData.selectedOrganizer,
    mc_meeting_language: formData.selectedLanguage,
    mc_meeting_program: formData.selectedProgram,
    animators: formData.selectedAnimators.map((animator: Animator) => animator.username),
    generate_zoom_link: formData.generateZoomLink,
  };

  ApiService.post(`cohorts/${cohortId}/meetings`, payload)
    .then((_) => {
      alert(translate(language, "PAGES.COHORT.MEETINGS.SUCCESS.CREATE_MEETING"));
      callback?.();
    })
    .catch((error) => {
      console.error("Failed to create meeting:", error);
      alert(translate(language, "PAGES.COHORT.MEETINGS.ERRORS.CREATE_MEETING"));
    });
};

export const styleMeetingsRow = (meetings: any): string => {
  return meetings.end.timestamp * 1000 < Date.now() ? "table-secondary" : "";
};
