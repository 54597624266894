import Papa from "papaparse";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import DstButton from "../../components/DstButton/DstButton";
import DstTable from "../../components/DstTable/DstTable";
import { useSession } from "../../contexts/SessionContext";
import translate from "../../services/Translate";
import { completeNavigate } from "../../services/UtilityService";
import { DiplomaImportFile, DiplomaImportStructuredData, DiplomaImportValidatedData } from "./DiplomaImport.d";
import { createImportedDiplomas, validateImportedDiplomas } from "./DiplomaImport.function";
import "./DiplomaImport.scss";

export const DiplomaImport = () => {
  const { language } = useSession();
  const [csvData, setCsvData] = useState<Array<DiplomaImportStructuredData>>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const [sendEmail] = useState<boolean>(true);
  const navigate = useNavigate();
  const [validatedData, setValidatedData] = useState<Array<DiplomaImportValidatedData>>([]);
  const [validating, setValidating] = useState<boolean>(false);
  const [editingBirthdate, setEditingBirthdate] = useState<string | null>(null);

  const handleFileUpload = useCallback(
    (file: File) => {
      Papa.parse<DiplomaImportFile>(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results: Papa.ParseResult<DiplomaImportFile>) => {
          if (results.errors.length > 0) {
            console.error("CSV Parse Errors:", results.errors);
            alert(translate(language, "PAGES.DIPLOMA_IMPORT.ALERT.ERROR_CSV_IMPORT"));
            return;
          }
          const transformedData = results.data.map((row: DiplomaImportFile) => ({
            cursus: row.Cursus,
            name: row.Name,
            birthdate: row.Birthdate,
            email: row.Email,
            is_mines: row["Is Mines"] === "1",
            in_english: row["Mail in English"] === "1",
            is_b2b: row.B2B === "1",
            school: row.School,
            is_ece: row.ECE === "1",
          }));

          setCsvData(transformedData);

          validateImportedDiplomas(transformedData, language, setValidatedData, setValidating);
        },
        error: (error: Error) => {
          console.error("Error parsing CSV:", error);
          alert(`${translate(language, "PAGES.DIPLOMA_IMPORT.ALERT.ERROR_CSV_IMPORT")} ${error.message}`);
        },
      });
    },
    [language]
  );

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  const handleDrop = useCallback(
    (event: DragEvent) => {
      event.preventDefault();
      const file = event.dataTransfer?.files[0];
      if (file) {
        handleFileUpload(file);
      }
    },
    [handleFileUpload]
  );

  useEffect(() => {
    const dropZone = dropZoneRef.current;

    if (dropZone) {
      const handleDragOver = (event: DragEvent) => {
        event.preventDefault();
        dropZone.classList.add("drag-over");
      };

      const handleDragLeave = () => {
        dropZone.classList.remove("drag-over");
      };

      dropZone.addEventListener("dragover", handleDragOver);
      dropZone.addEventListener("dragleave", handleDragLeave);
      dropZone.addEventListener("drop", handleDrop);

      return () => {
        dropZone.removeEventListener("dragover", handleDragOver);
        dropZone.removeEventListener("dragleave", handleDragLeave);
        dropZone.removeEventListener("drop", handleDrop);
      };
    }
  }, [handleDrop]);

  const handleBirthdateChange = (email: string, newDate: string) => {
    const updatedCsvData = csvData.map((item) => (item.email === email ? { ...item, birthdate: newDate } : item));

    setCsvData(updatedCsvData);
    setEditingBirthdate(null);
    validateImportedDiplomas(updatedCsvData, language, setValidatedData, setValidating);
  };

  const columns = [
    {
      name: translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.CURSUS"),
      key: "cursus",
    },
    {
      name: translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.NAME"),
      key: "name",
    },
    {
      name: translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.BIRTHDATE"),
      key: "birthdate",
      render: (line: DiplomaImportValidatedData & DiplomaImportStructuredData) => (
        <div>
          {editingBirthdate === line.email ? (
            <div className="d-flex align-items-center">
              <input
                type="text"
                className="form-control form-control-sm"
                defaultValue={line.birthdate}
                placeholder="YYYY-MM-DD"
                onBlur={(e) => handleBirthdateChange(line.email, e.target.value)}
                autoFocus
              />
            </div>
          ) : (
            <div className="cursor-pointer d-flex align-items-center" onClick={() => setEditingBirthdate(line.email)}>
              <span>{line.birthdate || translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.SELECT_BIRTHDATE")}</span>
              <i className="dst-icon-calendar ms-2" />
            </div>
          )}
        </div>
      ),
    },
    {
      name: translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.EMAIL"),
      key: "email",
    },
    {
      name: translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.IS_MINES"),
      key: "is_mines",
      render: (line: DiplomaImportStructuredData) =>
        line.is_mines
          ? translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.YES")
          : translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.NO"),
    },
    {
      name: translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.MAIL_IN_ENGLISH"),
      key: "in_english",
      render: (line: DiplomaImportStructuredData) =>
        line.in_english
          ? translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.YES")
          : translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.NO"),
    },
    {
      name: translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.B2B"),
      key: "is_b2b",
      render: (line: DiplomaImportStructuredData) =>
        line.is_b2b
          ? translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.YES")
          : translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.NO"),
    },
    {
      name: translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.SCHOOL"),
      key: "school",
    },
    {
      name: translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.ECE"),
      key: "is_ece",
      render: (line: DiplomaImportStructuredData) =>
        line.is_ece
          ? translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.YES")
          : translate(language, "PAGES.DIPLOMA_IMPORT.TABLE_HEADER.NO"),
    },
  ];

  return (
    <div className="p-3">
      <Row className="mb-4">
        <Col>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <i
                className="dst-icon-chevron-left p-2 megium-gray-background rounded me-2"
                onClick={(event: React.MouseEvent<HTMLDivElement>) => completeNavigate(event, navigate, "/diplomas")}
              />
              <h2 className="mb-0">{translate(language, "PAGES.DIPLOMA_IMPORT.TITLE")}</h2>
            </div>
            <div className="d-flex gap-2">
              {csvData.length > 0 && (
                <DstButton
                  btnImageBefore={<span className="dst-icon-upload-03 me-2" />}
                  value={translate(language, "PAGES.DIPLOMA_IMPORT.NEW_CSV_IMPORT")}
                  variant="light"
                  clickFunction={() => document.getElementById("file-input")?.click()}
                />
              )}
              <DstButton
                btnImageBefore={<span className="dst-icon-download-02 me-2" />}
                value={translate(language, "PAGES.DIPLOMA_IMPORT.DOWNLOAD_TEMPLATE")}
                variant="light"
                clickFunction={() => {
                  const link = document.createElement("a");
                  link.href = "/share/diploma_template.csv";
                  link.download = "import_diploma_template.csv";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              />
              <DstButton
                btnImageAfter={<span className="dst-icon-arrow-right ms-2" />}
                value={translate(language, "PAGES.DIPLOMA_IMPORT.CREATE_DIPLOMAS")}
                variant="primary"
                loading={submitting || validating}
                disabled={submitting || validating || validatedData.filter((user) => user.diplomable).length === 0}
                clickFunction={() => createImportedDiplomas(validatedData, language, setSubmitting, sendEmail)}
              />
            </div>
          </div>
        </Col>
      </Row>
      {csvData.length === 0 ? (
        <div className="d-flex flex-column gap-2 clickable">
          <DstTable data={csvData} columns={columns} itemsPerPage={20} />
          <div
            ref={dropZoneRef}
            className="border-2 border-dashed rounded-3 p-5 text-center d-flex flex-column align-items-center"
          >
            <div className="text-center s-regular d-flex align-items-center">
              {translate(language, "PAGES.DIPLOMA_IMPORT.DROP_FILE")}
              <DstButton
                value={translate(language, "PAGES.DIPLOMA_IMPORT.LOAD_LOCAL_FILE")}
                variant="link"
                btnClass="ps-1"
                clickFunction={() => document.getElementById("file-input")?.click()}
              />
            </div>
          </div>
        </div>
      ) : (
        <React.Fragment>
          <DstTable
            data={validatedData.map((vData) => {
              const originalData = csvData.find((item) => item.email === vData.email) || {};
              return { ...originalData, ...vData };
            })}
            columns={columns}
            itemsPerPage={20}
            classTable="xs-bold"
          />
        </React.Fragment>
      )}
      <input type="file" id="file-input" className="d-none" onChange={handleFileInputChange} />
    </div>
  );
};

export default DiplomaImport;
