import ApiService from "../../../../services/ApiService";
import translate from "../../../../services/Translate";
import { Animator, LanguageKeys, VisibilityKeys } from "./CreationModal.d";

export const visibilityOptions = (language: string): Record<VisibilityKeys, string> => ({
  datascientest: translate(language, "PAGES.MEETINGS.MODAL.OPTIONS.VISIBILITY.DATASCIENTEST"),
  private: translate(language, "PAGES.MEETINGS.MODAL.OPTIONS.VISIBILITY.PRIVATE"),
});

export const languageMapping = (language: string): Record<LanguageKeys, string> => ({
  FR: translate(language, "PAGES.MEETINGS.MODAL.OPTIONS.LANGUAGE.FRENCH"),
  EN: translate(language, "PAGES.MEETINGS.MODAL.OPTIONS.LANGUAGE.ENGLISH"),
});

export const getProjects = (setProjects: Function, language: string) => {
  ApiService.get("projects?only_active=true")
    .then((response) => {
      setProjects(
        response.data.projects.map((project: { id: number; name: string }) => ({
          value: project.id,
          display: project.name,
        }))
      );
    })
    .catch((err) => {
      console.error("Error fetching projects:", err);
      alert(translate(language, "PAGES.MEETINGS.MODAL.ERRORS.FETCH_PROJECTS"));
    });
};

export const getResources = (setAnimators: Function, setPrograms: Function, language: string) => {
  ApiService.get("meetings/resources")
    .then((response) => {
      const animatorsWithId = response.data.meeting_staff_and_programs.animators.map(
        (animator: Animator, index: number) => ({
          ...animator,
          id: index,
        })
      );
      setAnimators(animatorsWithId);
      setPrograms(response.data.meeting_staff_and_programs.programs || []);
    })
    .catch((error) => {
      console.error("Failed to get animators and organizers:", error);
      alert(translate(language, "PAGES.MEETINGS.MODAL.ERRORS.GET_ADMINS"));
    });
};

export const createMeeting = (payload: any, language: string, setLoading: Function, sucessCallback?: Function) => {
  setLoading(true);
  ApiService.post("meetings", payload)
    .then((_) => {
      alert(translate(language, "PAGES.MEETINGS.MODAL.SUCCESS.CREATE_MEETING"));
      sucessCallback?.();
    })
    .catch((error) => {
      console.error("Error creating meeting:", error);
      alert(translate(language, "PAGES.MEETINGS.MODAL.ERRORS.CREATE_MEETING"));
    })
    .finally(() => {
      setLoading(false);
    });
};
