import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.scss";
import { SessionProvider } from "./contexts/SessionContext";
import Cohort from "./pages/Cohort/Cohort";
import Cohorts from "./pages/Cohorts/Cohorts";
import Correction from "./pages/Correction/Correction";
import DiplomaImport from "./pages/DiplomaImport/DiplomaImport";
import EvaluationAttempts from "./pages/EvaluationAttempts/EvaluationAttempts";
import Home from "./pages/Home/Home";
import Insights from "./pages/Insights/Insights";
import InsightsSurvey from "./pages/InsightsSurvey/InsightsSurvey";
import Login from "./pages/Login/Login";
import Meeting from "./pages/Meeting/Meeting";
import MeetingImport from "./pages/MeetingImport/MeetingImport";
import MeetingTypes from "./pages/MeetingTypes/MeetingTypes";
import Meetings from "./pages/Meetings/Meetings";
import Messages from "./pages/Messages/Messages";
import NotFound from "./pages/NotFound/NotFound";
import Permissions from "./pages/Permissions/Permissions";
import User from "./pages/User/User";
import UserSearch from "./pages/UserSearch/UserSearch";
import Users from "./pages/Users/Users";
import { ProtectedRoute } from "./services/AuthGuard";

const App = () => {
  return (
    <BrowserRouter>
      <SessionProvider>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/:user_id"
            element={
              <ProtectedRoute>
                <User />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user-by-email/:email"
            element={
              <ProtectedRoute>
                <UserSearch />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cohorts"
            element={
              <ProtectedRoute>
                <Cohorts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cohort/:cohort_id"
            element={
              <ProtectedRoute>
                <Cohort />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedRoute>
                <Users />
              </ProtectedRoute>
            }
          />
          <Route
            path="/evaluations/attempts"
            element={
              <ProtectedRoute>
                <EvaluationAttempts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/evaluation-attempt/:eval_id"
            element={
              <ProtectedRoute>
                <Correction />
              </ProtectedRoute>
            }
          />
          <Route
            path="/insights"
            element={
              <ProtectedRoute>
                <Insights />
              </ProtectedRoute>
            }
          />
          <Route
            path="/insights/surveys"
            element={
              <ProtectedRoute>
                <InsightsSurvey />
              </ProtectedRoute>
            }
          />
          <Route
            path="/meetings"
            element={
              <ProtectedRoute>
                <Meetings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/meeting/:meeting_id"
            element={
              <ProtectedRoute>
                <Meeting />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messages"
            element={
              <ProtectedRoute>
                <Messages />
              </ProtectedRoute>
            }
          />
          <Route
            path="/permissions"
            element={
              <ProtectedRoute>
                <Permissions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/meetings/import"
            element={
              <ProtectedRoute>
                <MeetingImport />
              </ProtectedRoute>
            }
          />
          <Route
            path="/meetings/types"
            element={
              <ProtectedRoute>
                <MeetingTypes />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <NotFound />
              </ProtectedRoute>
            }
          />
          <Route
            path="/diplomas/import"
            element={
              <ProtectedRoute>
                <DiplomaImport />
              </ProtectedRoute>
            }
          />
        </Routes>
      </SessionProvider>
    </BrowserRouter>
  );
};

export default App;
