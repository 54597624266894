import { useEffect, useState } from "react";
import { Col, OverlayTrigger, Placeholder, Row, Tooltip } from "react-bootstrap";

import DstButton from "../../components/DstButton/DstButton";
import DstTable from "../../components/DstTable/DstTable";
import DstTableSearch from "../../components/DstTable/DstTableSearch/DstTableSearch";
import { useSession } from "../../contexts/SessionContext";
import translate from "../../services/Translate";
import { MeetingType } from "./MeetingTypes.d";
import { fetchMeetingTypes } from "./MeetingTypes.function";

const MeetingTypes = () => {
  const { language } = useSession();
  const [meetingTypes, setMeetingTypes] = useState<MeetingType[]>([]);
  const [filteredMeetingTypes, setFilteredMeetingTypes] = useState<MeetingType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMeetingTypes(setMeetingTypes, setLoading, language);
  }, [language]);

  const columns = [
    {
      name: translate(language, "PAGES.MEETING_TYPES.TABLE.TYPE"),
      key: "meeting_meta_type",
      render: (item: MeetingType) => item.meeting_meta_type || translate(language, "PAGES.MEETING_TYPES.NO_DATA"),
    },
    {
      name: translate(language, "PAGES.MEETING_TYPES.TABLE.NAME"),
      key: "meeting_type_name",
      render: (item: MeetingType) => item.meeting_type_name || translate(language, "PAGES.MEETING_TYPES.NO_DATA"),
    },
    {
      name: translate(language, "PAGES.MEETING_TYPES.TABLE.PROGRAM"),
      key: "meeting_type_extra_data.program",
      render: (item: MeetingType) => item.meeting_type_extra_data?.program || "N/A",
    },
    {
      name: translate(language, "PAGES.MEETING_TYPES.TABLE.DESCRIPTION"),
      key: "meeting_type_description",
      render: (item: MeetingType) =>
        item.meeting_type_description || translate(language, "PAGES.MEETING_TYPES.NO_DATA"),
    },
    {
      name: translate(language, "PAGES.MEETING_TYPES.TABLE.LANGUAGE"),
      key: "meeting_type_extra_data.language",
      render: (item: MeetingType) => item.meeting_type_extra_data?.language || "N/A",
    },
    {
      name: translate(language, "PAGES.MEETING_TYPES.TABLE.LINK"),
      key: "meeting_type_link",
      render: (item: MeetingType) => item.meeting_type_link || translate(language, "PAGES.MEETING_TYPES.NO_DATA"),
    },
    {
      name: translate(language, "PAGES.MEETING_TYPES.TABLE.ID"),
      key: "meeting_type_id",
      render: (item: MeetingType) => (
        <OverlayTrigger
          trigger="focus"
          delay={{ show: 0, hide: 1500 }}
          rootClose
          overlay={
            <Tooltip id="tooltip-copied">
              {translate(language, "PAGES.MEETING_TYPES.COPY_SUCCESS")}
              <span className="dst-icon-check-done-02"></span>
            </Tooltip>
          }
        >
          <span
            onClick={() => navigator.clipboard.writeText(item.meeting_type_id)}
            className="clickable text-primary"
            tabIndex={0}
          >
            {item.meeting_type_id || translate(language, "PAGES.MEETING_TYPES.NO_DATA")}
          </span>
        </OverlayTrigger>
      ),
    },
  ];

  const searchableColumns = [
    "meeting_meta_type",
    "meeting_type_name",
    "meeting_type_description",
    "meeting_type_extra_data.language",
    "meeting_type_extra_data.program",
    "meeting_type_id",
    "meeting_type_link",
  ];

  if (loading) {
    return (
      <div className="p-3">
        <Row className="mb-3">
          <Col>
            <Placeholder animation="glow">
              <Placeholder xs={6} />
            </Placeholder>
          </Col>
        </Row>
        <Placeholder as="div" animation="glow">
          <Placeholder xs={12} className="mb-2" style={{ height: "40px" }} />
          <Placeholder xs={12} className="mb-2" style={{ height: "40px" }} />
          <Placeholder xs={12} className="mb-2" style={{ height: "40px" }} />
        </Placeholder>
      </div>
    );
  }

  return (
    <div className="p-3">
      <Row className="d-flex justify-content-between mb-3">
        <Col xl className="d-flex align-items-center">
          <h1 className="mb-0 h2 me-3">{translate(language, "PAGES.MEETING_TYPES.TITLE")}</h1>
          <DstTableSearch
            data={meetingTypes}
            searchableColumns={searchableColumns}
            onSearch={setFilteredMeetingTypes}
          />
        </Col>
        <Col xl className="d-flex justify-content-xl-end mt-3 mt-xl-0">
          <DstButton
            btnClass="me-2"
            variant="primary"
            value={translate(language, "PAGES.MEETING_TYPES.BUTTON.ADD_MEETING_TYPE")}
            btnImageAfter={<i className="dst-icon-arrow-right ms-2" />}
            clickFunction={() => alert("WIP")}
          />
        </Col>
      </Row>
      <DstTable
        columns={columns}
        data={filteredMeetingTypes.length > 0 ? filteredMeetingTypes : meetingTypes}
        striped
        pagination
        itemsPerPage={10}
        paginationResizeOptions={[10, 50, 100]}
      />
    </div>
  );
};

export default MeetingTypes;
